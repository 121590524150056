/**
 * @author: Abdul Pathan
 */

import React from 'react';
import moment from 'moment';

const TimeSheetPdf = ({ monthyear }) => {
    // console.log('monthyear ==>', monthyear?.data);
    let totalHours = 0.0;
    let totalOvertime = 0.0;

    const isWeekend = (dateString) => {
        const dayOfWeek = new Date(dateString).getDay();
        return dayOfWeek === 0 || dayOfWeek === 6; // 0 represents Sunday, 6 represents Saturday
    };

    // Row color show || leave, Holiday, Weekend
    const getRowColor = (item) => {
        if (item.leave === "Holiday") {
            return "#E1F0FE";
        } else if (item.leave === "Full day") {
            return "#FEF3DE";
        } else if (item.leave === "Half day") {
            return "#C9F6F5";
        } else if (isWeekend(item.workDate)) {
            return "#FEE2E5";
        } else {
            return "";
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center border" style={{ fontSize: '10px' }}>
                <table style={{ width: '100%' }}>
                    <tr>
                        <td style={{ border: 'None', textAlign: 'center' }}>
                            <h4>Employee Monthly Timesheet</h4>
                        </td>
                    </tr>
                </table>
                <hr />
                <table style={{ fontSize: '15px' }}>
                    <tr>
                        <td style={{ width: '70%', height: '30px', border: 'none', }}>
                            <b>Employee Name: </b> {monthyear?.data?.contactName}
                        </td>
                        <td style={{ width: '30%', height: '30px', border: 'none' }}>
                            <b>Month:</b> {monthyear?.monthname} {monthyear?.year}
                        </td>
                    </tr>
                </table>

                <table style={{ fontSize: '10px', margin: "0" }}>
                    <tr>
                        <td style={{ width: "20", height: '20px', border: 'none', }}>
                            <svg width="20" height="10">
                                <rect width="20" height="10" style={{ fill: "#E1F0FE" }} />
                            </svg>
                        </td>
                        <td style={{ border: 'none', fontSize: "bold" }}>
                            Holiday
                        </td>

                        <td style={{ border: 'none', }}></td>

                        <td style={{ width: "20", height: '20px', border: 'none', }}>
                            <svg width="20" height="10">
                                <rect width="20" height="10" style={{ fill: "#FEE2E5" }} />
                            </svg>
                        </td>
                        <td style={{ border: 'none', }}>
                            Weekends
                        </td>

                        <td style={{ border: 'none', }}></td>

                        <td style={{ width: "20", height: '20px', border: 'none', }}>
                            <svg width="20" height="10">
                                <rect width="20" height="10" style={{ fill: "#FEF3DE" }} />
                            </svg>
                        </td>
                        <td style={{ border: 'none', }}>
                            Full day Leave
                        </td>

                        <td style={{ border: 'none', }}></td>

                        <td style={{ width: "20", height: '20px', border: 'none', }}>
                            <svg width="20" height="10">
                                <rect width="20" height="10" style={{ fill: "#C9F6F5" }} />
                            </svg>
                        </td>
                        <td style={{ border: 'none', }}>
                            Half day Leave
                        </td>

                        <td style={{ border: 'none', }}></td>

                        <td style={{ width: "20", height: '20px', border: 'none', }}>
                            <svg width="20" height="10">
                                <rect width="20" height="10" style={{ fill: "#FEF9A8" }} />
                            </svg>
                        </td>
                        <td style={{ border: 'none', }}>
                            Overtime
                        </td>
                    </tr>
                </table>


                <table
                    id="pdf-content"
                    style={{ fontSize: '12px', borderCollapse: 'collapse', width: '100%' }}
                >
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>
                                <strong> Date</strong>
                            </th>
                            <th style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>
                                <strong>Project</strong>
                            </th>
                            <th style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>
                                <strong>Hours</strong>
                            </th>
                            <th style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>
                                <strong>Overtime</strong>
                            </th>
                            <th style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>
                                <strong>Description</strong>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {monthyear?.data?.monthlyDetails?.map((item, index) => {
                            let dailyHours = 0.0;
                            let dailyOvertime = item.overtime;

                            return (
                                <React.Fragment key={index}>
                                    {item.projects.length === 0 && item.overtime === 0 && (
                                        <tr key={`${index}`} >
                                            <td style={{ width: '15%', marginTop: '5px', marginBottom: '5px' }}>
                                                {moment(item.workDate).format('DD-MM-YYYY')}
                                            </td>
                                            <td></td>
                                            <td style={{ marginTop: '5px', marginBottom: '5px' }}>0</td>
                                            <td style={{ marginTop: '5px', marginBottom: '5px' }}>0</td>
                                            <td></td>
                                        </tr>
                                    )}

                                    {item.projects.map((project, projectIndex) => {
                                        dailyHours += parseFloat(project.workingHours);
                                        return (
                                            <tr key={`${index}-${projectIndex}`}>
                                                {projectIndex === 0 && (
                                                    <React.Fragment>
                                                        <td rowSpan={item.projects.length}
                                                            style={{
                                                                width: '15%',
                                                                marginTop: '5px',
                                                                backgroundColor: getRowColor(item)
                                                            }}
                                                        >
                                                            {moment(item.workDate).format('DD-MM-YYYY')}
                                                        </td>
                                                        <td style={{ width: '30%', marginTop: "5px", backgroundColor: getRowColor(item) }}>
                                                            {project.projectName}
                                                        </td>
                                                        <td style={{ width: '10%', marginTop: "5px", backgroundColor: getRowColor(item) }}>
                                                            {project.workingHours}
                                                        </td>
                                                        <td
                                                            rowSpan={item.projects.length}
                                                            style={{
                                                                width: '10%',
                                                                marginTop: '5px',
                                                                marginBottom: "5px",
                                                                backgroundColor: item.overtime > 0 ? '#FEF9A8' : getRowColor(item),
                                                            }}
                                                        >
                                                            {item.overtime}
                                                        </td>
                                                        {/* <td rowSpan={item.projects.length} style={{ width: '40%', backgroundColor: getRowColor(item) }}>
                                                            {project.description}
                                                        </td> */}
                                                        <td rowSpan={item.projects.length >= 1} style={{ width: '40%', backgroundColor: getRowColor(item) }}>
                                                            {project.description}
                                                        </td>
                                                    </React.Fragment>

                                                )}
                                                {projectIndex !== 0 && (
                                                    <React.Fragment>
                                                        <td style={{ width: '30%', marginTop: "2px", backgroundColor: getRowColor(item) }}>{project.projectName}</td>
                                                        <td style={{ width: '10%', backgroundColor: getRowColor(item) }}>{project.workingHours}</td>
                                                        <td style={{ width: '40%', backgroundColor: getRowColor(item) }}>{project.description}</td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        );
                                    })}

                                    <div className="App container mt-5" style={{ display: 'none' }}>
                                        {totalHours += dailyHours}
                                        {totalOvertime += dailyOvertime}
                                    </div>

                                </React.Fragment>
                            );
                        })}
                        <tr>
                            <td style={{ backgroundColor: '#EEE5FE', padding: '10px' }}></td>
                            <td style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}><b>TOTAL HOURS</b></td>
                            <td style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}><b></b>{totalHours.toFixed(1)}</td>
                            <td style={{ backgroundColor: '#EEE5FE', color: '#000000', padding: '10px', fontWeight: "bold" }}>{totalOvertime.toFixed(1)}</td>
                            <td style={{ backgroundColor: '#EEE5FE', padding: '10px' }}></td>
                        </tr>
                    </tbody>
                </table>
            </div >
        </>
    );
};

export default TimeSheetPdf;
