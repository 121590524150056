/*
    @author: Abdul Pathan
*/
import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
// import { Link, useLocation } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import HRMSApi from "../api/HRMSApi";
import Footer from './layout/Footer';
import { ShimmerTable } from 'react-shimmer-effects';
import HolidaysCalender from './calender/HolidaysCalender';
import jwt_decode from "jwt-decode";
import { formatNumberWithDecimalsHome } from '../components/common/helper';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as constants from "../constants/CONSTANT"

const Home = () => {
  const navigate = useNavigate();
  const [contactRecords, setContactRecords] = useState();
  const [totalDetail, setTotalDetail] = useState();
  const [showHideTotalSalary, setShowHideTotalSalary] = useState(false);
  const [showHideIncome, setShowHideIncome] = useState(false);
  const [showHideTaxDeducted, setShowHideTaxDeducted] = useState(false);
  const [showHideCarryForwardCLs, setShowHideCarryForwardCLs] = useState(false);
  const [showHideTotalCl, setShowHideTotalCl] = useState(false);
  const [empData, setEmpData] = useState();
  const [showHide, setShowHide] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(true);
  const [employeeInfo, setEmployeeInfo] = useState();
  const [isSpinner, setIsSpinner] = useState(true);
  const [accountName, setAccountName] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);
    const contactDetailsString = decodedToken.contactDetails;
    setAccountName(contactDetailsString?.Name)
    fetchContactRecs(contactDetailsString.Email);
    fetchEmpNotifications();
  }, []);

  // fetch salesforce contact records
  const fetchContactRecs = async (userEmail) => {
    if (userEmail) {
      setIsFormChanged(true);
      const result = await HRMSApi.getContactRecordByEmail(userEmail);
      if (result.success) {
        setContactRecords(result?.records);
        if (result?.records?.Id) {
          const resultPayroll = await HRMSApi.getPayRollRecordById(result?.records?.Id);
          if (resultPayroll.success) {
            if (resultPayroll.records[0].totalAddIncome === null) {
              resultPayroll.records[0].totalAddIncome = 0;
              setTotalDetail(resultPayroll.records[0]);
            }
          }
        }
      }
    }
  }

  const [sortedBirthdaysList, setSortedBirthdaysList] = useState([]);
  const [sortedMarriageAsList, setSortedMarriageAList] = useState([]);
  // fetch employee birthday and marriage anniversary records
  const fetchEmpNotifications = async () => {
    const result = await HRMSApi.getEmployeeRecords();
    if (result.success) {
      setEmployeeInfo(result.records);
      const sortedList = sortBirthdaysByDate(result.records?.birthdaysList);
      const sortedList2 = sortMarriageAByDate(result.records?.marriageAnniversaryList);
      setSortedBirthdaysList(sortedList);
      setSortedMarriageAList(sortedList2);
    }
  }

  const sortBirthdaysByDate = (list) => {
    return list.sort((a, b) => new Date(a?.empBirthDate) - new Date(b?.empBirthDate));
  };
  const sortMarriageAByDate = (list) => {
    return list.sort((a, b) => new Date(a?.empMADate) - new Date(b?.empMADate));
  };

  // Total salary show/hide
  const totalSalaryShowHide = () => {
    setShowHideTotalSalary(!showHideTotalSalary);
  }
  //Additional income show/hide
  const additionalIncomeShowHide = () => {
    setShowHideIncome(!showHideIncome);
  }
  //Tax deducted show/hide
  const taxDeductedShowHide = () => {
    setShowHideTaxDeducted(!showHideTaxDeducted);
  }
  //Carry forward cls show/hide
  const carryForwardCLsShowHide = () => {
    setShowHideCarryForwardCLs(!showHideCarryForwardCLs);
  }

  //total CL show/hide
  const totalCLShowHide = () => {
    setShowHideTotalCl(!showHideTotalCl);
  }

  // edit employee details
  const editEmployeeInfo = (event) => {
    setShowHide(true)
    const employeeObj = {
      firstName: event.FirstName,
      lastName: event.LastName,
      email: event.Email,
      phoneNumber: event.Phone,
      dateOfBirth: event.Birthdate,
      dateOfMarriage: event.Date_of_Marriage__c,
      address: event.Permanent_Address__c
    }
    setEmpData(employeeObj);
  }
  // handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmpData(prevState => ({ ...prevState, [name]: value }));
  }
  // save record
  const handleSubmit = async () => {
    if (empData.firstName && empData.lastName && empData.phoneNumber && empData.dateOfBirth && empData.address) {
      setIsFormChanged(false);
      setIsSpinner(false);
      const result = await HRMSApi.updateEmployeeRecords(empData);
      if (result.success && result.message.toLowerCase() === 'Your information updated successfully.'.toLowerCase()) {
        toast.success(result.message, { position: toast.POSITION.TOP_RIGHT });
        fetchContactRecs(empData.email);
        navigate("/", { state: { empData: 'refresh_EmployeeName' } });
        // window.location.reload();
        setShowHide(false);
        setIsSpinner(true);
      } else {
        setIsFormChanged(true);
        setIsFormChanged(true);
        toast.error('Required fields are missing.', { position: toast.POSITION.TOP_RIGHT });
      }
    } else {
      toast.error("Required fields are missing.", { position: toast.POSITION.TOP_RIGHT });
    }
  }
  // close
  const handleClosed = () => {
    setShowHide(false);
  }


  const initialItemsToShow = 3;
  // State for birthdays list
  const [birthdaysToShow, setBirthdaysToShow] = useState(initialItemsToShow);
  const [birthdaysExpanded, setBirthdaysExpanded] = useState(false);
  // State for marriage anniversaries list
  const [anniversariesToShow, setAnniversariesToShow] = useState(initialItemsToShow);
  const [anniversariesExpanded, setAnniversariesExpanded] = useState(false);

  const toggleShowMoreBirthdays = () => {
    if (birthdaysExpanded) {
      setBirthdaysToShow(initialItemsToShow);
    } else {
      setBirthdaysToShow(employeeInfo.birthdaysList.length);
    }
    setBirthdaysExpanded(!birthdaysExpanded);
  };

  const toggleShowMoreAnniversaries = () => {
    if (anniversariesExpanded) {
      setAnniversariesToShow(initialItemsToShow);
    } else {
      setAnniversariesToShow(employeeInfo.marriageAnniversaryList.length);
    }
    setAnniversariesExpanded(!anniversariesExpanded);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const suffix = getDateSuffix(day);
    return `${day}${suffix} ${month}`;
  }

  function getDateSuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  return (
    <>
      {contactRecords ?
        <>
          {/* BOX || START */}
          <Container>
            <Row className='mx-3'>
              <Col lg={12} sm={12} xs={12}>
                <Row>
                  <Col lg={3} sm={12} xs={12} >
                    <div className="p-2 mt-3" style={{ backgroundColor: "white", borderTop: "5px solid #FEE2E5", borderRadius: "5px", cursor: "pointer" }} onClick={totalSalaryShowHide} >
                      <div className="col-12 col-md-12 text-center">
                        <span className="fa-stack fa-2x">
                          <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#FEE2E5" }} ></i>
                          <i className="fa-indian-rupee-sign fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                        </span>
                      </div>
                      <div className="col-12 col-md-12 text-center mt-2">
                        <h4 style={{ color: "black !important", fontWeight: "600", fontSize: "15px" }}>Total Salary</h4>
                      </div>
                      <div className="col-12 col-md-12 text-center mt-2">
                        <h3 className='m-0'>
                          {showHideTotalSalary ? (totalDetail?.totalSalary == null ? '0.0' : formatNumberWithDecimalsHome(Math.ceil(totalDetail?.totalSalary).toLocaleString('en-IN'))) : ''}
                        </h3>
                      </div>
                    </div>
                  </Col>

                  <Col lg={3} sm={12} xs={12} >
                    <div className="p-2 mt-3" style={{ backgroundColor: "white", borderTop: "5px solid #DDBDAE", borderRadius: "5px", cursor: "pointer" }} onClick={additionalIncomeShowHide}>
                      <div className="col-12 col-md-12 col-sm-12 text-center">
                        <span className="fa-stack fa-2x">
                          <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#DDBDAE" }} ></i>
                          <i className="fa-solid fa-arrow-trend-up fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }} ></i>
                        </span>
                      </div>
                      <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                        <h4 style={{ color: "black !important", fontWeight: "600", fontSize: "15px" }}>Additional Income</h4>
                      </div>
                      <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                        <h3 className='m-0'>
                          {showHideIncome ? (totalDetail?.totalAddIncome == null ? '0.0' : parseFloat(totalDetail?.totalAddIncome / 100000).toFixed(2)) : ''}
                        </h3>
                      </div>
                    </div>
                  </Col>

                  {accountName === constants?.Company_Name ?
                    <Col lg={3} sm={12} xs={12}>
                      <div className="p-2 mt-3" style={{ backgroundColor: "white", borderTop: "5px solid #d2c4eb", borderRadius: "5px", cursor: "pointer" }} onClick={carryForwardCLsShowHide}>
                        <div className="col-12 col-md-12 col-sm-12 text-center">
                          <span className="fa-stack fa-2x">
                            <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#d2c4eb" }} ></i>
                            <i className="fa-solid fa-bolt fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                          </span>
                        </div>
                        <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                          <h4 style={{ color: "black !important", fontWeight: "600", fontSize: "15px" }}>Carry Forward CLs</h4>
                        </div>
                        <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                          <h3 className='m-0'>
                            {showHideCarryForwardCLs ? (
                              <>
                                {contactRecords.Carry_Forward_CL__c - contactRecords.Used_Carry_Forward_CL__c}
                                <h5 className="mb-0 d-inline ">={contactRecords.Carry_Forward_CL__c}-{contactRecords.Used_Carry_Forward_CL__c}</h5>
                              </>
                            ) :
                              ''}
                          </h3>
                        </div>
                      </div>
                    </Col>
                    :
                    <Col lg={3} sm={12} xs={12} >
                      <div className="p-2 mt-3" style={{ backgroundColor: "white", borderTop: "5px solid #d2c4eb", borderRadius: "5px", cursor: "pointer" }} onClick={totalCLShowHide}>
                        <div className="col-12 col-md-12 col-sm-12 text-center">
                          <span className="fa-stack fa-2x">
                            <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#d2c4eb" }} ></i>
                            <i className="fa-solid fa-bolt fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                          </span>
                        </div>
                        <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                          <h4 style={{ color: "black !important", fontWeight: "600", fontSize: "15px" }}>Total CLs</h4>
                        </div>
                        <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                          <h3 className='m-0'>
                            {showHideTotalCl ? (totalDetail?.totalCls == null ? '0.0' : formatNumberWithDecimalsHome(Math.ceil(totalDetail?.totalCls).toLocaleString('en-IN'))) : ''}
                          </h3>
                        </div>
                      </div>
                    </Col>

                  }

                  <Col lg={3} sm={12} xs={12} >
                    <div className="p-2 mt-3" style={{ backgroundColor: "white", borderTop: "5px solid rgb(144 231 245)", borderRadius: "5px", cursor: "pointer" }} onClick={taxDeductedShowHide}>
                      <div className="col-12 col-md-12 col-sm-12 text-center">
                        <span className="fa-stack fa-2x">
                          <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(144 231 245)" }} ></i>
                          <i className="fa-scissors fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                        </span>
                      </div>
                      <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                        <h4 style={{ color: "black !important", fontWeight: "600", fontSize: "15px" }}>Tax Deducted</h4>
                      </div>
                      <div className="col-12 col-md-12 col-sm-12 text-center mt-2">
                        <h3 className='m-0'>
                          {showHideTaxDeducted ? (totalDetail?.totalTax == null ? '0.0' : formatNumberWithDecimalsHome(Math.ceil(totalDetail?.totalTax).toLocaleString('en-IN'))) : ''}
                        </h3>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {/* BOX || END */}

          <Container className='pb-2'>
            <Row className='mt-3 mx-3'>
              {/* Employee Details || START */}
              <Col lg={8} sm={12} xs={12}>
                <Row>
                  <Col lg={12} sm={12} xs={12} className='mt-1'>
                    {!showHide ?
                      <Card className="mt-1" style={{ borderRadius: "5px", }}>
                        <Card.Header>
                          <Row>
                            <Col lg={6} sm={8} xs={8} >
                              <h4 className="text-light fw-semibold mb-0" style={{ fontSize: "16px" }}>Employee Details</h4>
                            </Col>
                            <Col lg={6} sm={4} xs={4} className='text-end'>
                              <i className="fa-regular fa-pen-to-square p-1" title='Edit Profile' style={{ cursor: "pointer", color: "white" }} onClick={() => editEmployeeInfo(contactRecords)}></i>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Card.Body>
                          <Row className='mx-1'>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>First Name:</strong><span className='mx-3'>{contactRecords.FirstName}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Last Name:</strong><span className='mx-3'>{contactRecords.LastName}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Designation:</strong><span className='mx-3'>{contactRecords.Designation__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Gender:</strong><span className='mx-3'>{contactRecords.Gender__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Email:</strong><span className='mx-3'>{contactRecords.Email}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Mobile:</strong><span className='mx-3'>{contactRecords.Phone}</span></Col>
                            <Col lg={12} sm={12} xs={12} className='p-2'><strong>Address:</strong><span className='mx-3'>{contactRecords.Permanent_Address__c}</span></Col>
                            <br /><br /><hr /><br />
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Experience:</strong><span className='mx-3'>{contactRecords.Current_Experience__c == null ? '0' : contactRecords.Current_Experience__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Date Of Birth:</strong><span className='mx-3'>{contactRecords.Birthdate == null ? '' : moment(contactRecords.Birthdate).format('DD-MM-YYYY')}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Date of Marriage:</strong><span className='mx-3'>{contactRecords.Date_of_Marriage__c == null ? '' : moment(contactRecords.Date_of_Marriage__c).format('DD-MM-YYYY')}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Date of Joining:</strong><span className='mx-3'> {contactRecords.Date_of_Joining__c == null ? '' : moment(contactRecords.Date_of_Joining__c).format('DD-MM-YYYY')}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Reporting Person:</strong><span className='mx-3'> {contactRecords.Reporting_Person__r.Name}</span></Col>
                            <br /><br /><hr />  <br />
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Bank Name:</strong><span className='mx-3'>{contactRecords.Bank_Name__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>Bank Account Number:</strong><span className='mx-3'>{contactRecords.Bank_Account_Number__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>PF Number:</strong><span className='mx-3'>{contactRecords.PF_Number__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>ESIC Number:</strong><span className='mx-3'>{contactRecords.ESIC_Number__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>PAN Card Number:</strong><span className='mx-3'>{contactRecords.PAN_Card_Number__c}</span></Col>
                            <Col lg={6} sm={12} xs={12} className='p-2'><strong>UAN Number:</strong><span className='mx-3'>{contactRecords.UAN_Number__c}</span></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      :
                      <Card style={{ borderRadius: "5px", }}>
                        <Card.Header>
                          <Row>
                            <Col lg={12} sm={12} xs={12} >
                              <h4 className="text-light fw-semibold mb-0" style={{ fontSize: "16px" }}>Employee Details</h4>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Card.Body>
                          {isSpinner ?
                            <Row className='mx-1 mb-4'>
                              <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mt-1'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">First Name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    name="firstName"
                                    value={empData.firstName || ''}
                                    onChange={handleChange}
                                    maxLength={40}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6} sm={12} xs={12} >
                                <Form.Group className='mt-1'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    name="lastName"
                                    value={empData.lastName || ''}
                                    onChange={handleChange}
                                    maxLength={40}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mt-3'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Phone</Form.Label>
                                  <Form.Control
                                    required
                                    type="phone"
                                    name="phoneNumber"
                                    value={empData.phoneNumber || ''}
                                    onChange={handleChange}
                                    maxLength={20}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mt-3'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Birthdate</Form.Label>
                                  <Form.Control
                                    required
                                    type="date"
                                    name="dateOfBirth"
                                    value={empData.dateOfBirth || ''}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mt-3'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Date of Marriage</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="dateOfMarriage"
                                    value={empData.dateOfMarriage || ''}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mt-3'>
                                  <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Address</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    name="address"
                                    value={empData.address || ''}
                                    onChange={handleChange}
                                    maxLength={255}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            :
                            <div>
                              <div className="sk-cube-grid">
                                <div className="sk-cube sk-cube1"></div>
                                <div className="sk-cube sk-cube2"></div>
                                <div className="sk-cube sk-cube3"></div>
                                <div className="sk-cube sk-cube4"></div>
                                <div className="sk-cube sk-cube5"></div>
                                <div className="sk-cube sk-cube6"></div>
                                <div className="sk-cube sk-cube7"></div>
                                <div className="sk-cube sk-cube8"></div>
                                <div className="sk-cube sk-cube9"></div>
                              </div>
                            </div>
                          }
                        </Card.Body>
                        <Card.Footer style={{ backgroundColor: "white" }} >
                          <Row>
                            <Col lg={12} sm={12} xs={12} className="text-end">
                              <button className='btn btn-outline-primary mx-2' onClick={handleClosed} disabled={!isFormChanged}>Close</button>
                              <button className='btn btn-outline-primary' onClick={handleSubmit} disabled={!isFormChanged}>Save</button>
                            </Col>
                          </Row>
                        </Card.Footer>
                      </Card>
                    }
                  </Col>

                  <Col lg={12} sm={12} xs={12} className='mb-3'>
                    <Row>
                      <Col lg={6} sm={12} xs={12} className='mb-2'>
                        <Card className='h-100 mt-3' style={{ height: "27rem", color: "white", backgroundColor: "rgb(99, 115, 132)" }}>
                          <Card.Header style={{ border: "none" }}>
                            <h4 className="text-light fw-semibold mb-0" style={{ fontSize: "16px" }}>Birthdays
                              <i className="fa-solid fa-cake-candles fa-beat mx-2" style={{ fontSize: "13px" }}></i>
                            </h4>
                          </Card.Header>
                          {sortedBirthdaysList.length > 0 ?
                            <>
                              <Card.Body className='pb-0'>
                                {sortedBirthdaysList.slice(0, birthdaysToShow).map((element, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-12 pb-2 d-flex align-items-center">
                                      <div className="col-4">
                                        <span style={{ fontSize: "11px" }}>
                                          {formatDate(element.empBirthDate)}
                                        </span>
                                      </div>
                                      <div className="col-10 d-flex align-items-center">
                                        <img className='mb-1' alt="User" src={element.imageURL ? element.imageURL : 'default_emp_profile.png'} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                        <span className='mx-2' style={{ fontSize: "11px" }}>{element.employeeName}</span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                              <Card.Footer style={{ border: "none" }}>
                                <Row>
                                  <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                    {sortedBirthdaysList.length > initialItemsToShow && (
                                      <button className='btn btn-sm float-end' onClick={toggleShowMoreBirthdays} style={{ color: "white" }}>
                                        {birthdaysExpanded ? 'Show less...' : 'Show more...'}
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </>
                            :
                            <Card.Body>
                              No data available.
                            </Card.Body>
                          }
                        </Card>
                      </Col>

                      <Col lg={6} sm={12} xs={12} className='mb-2'>
                        <Card className='h-100 mt-3' style={{ height: "27rem", color: "white", backgroundColor: "rgb(99, 115, 132)" }}>
                          <Card.Header style={{ border: "none" }}>
                            <h4 className="text-light fw-semibold mb-0" style={{ fontSize: "16px" }}>Marriage Anniversary
                              <i className="fa-solid fa-cake-candles fa-beat mx-2" style={{ fontSize: "13px" }}></i>
                            </h4>
                          </Card.Header>
                          {sortedMarriageAsList.length > 0 ?
                            <>
                              <Card.Body>
                                {sortedMarriageAsList.slice(0, anniversariesToShow).map((element, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-12 pb-2 d-flex align-items-center">
                                      <div className="col-3">
                                        <span style={{ fontSize: "11px" }}>
                                          {formatDate(element.empMADate)}
                                        </span>
                                      </div>
                                      <div className="col-9 d-flex align-items-center">
                                        <img className='mb-1' alt="User" src={element.imageURL ? element.imageURL : 'default_emp_profile.png'} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                        <span className='mx-2' style={{ fontSize: "11px" }}>{element.employeeName}</span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                              <Card.Footer style={{ border: "none" }}>
                                <Row>
                                  <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                    {sortedMarriageAsList.length > initialItemsToShow && (
                                      <button className='btn btn-sm float-end' onClick={toggleShowMoreAnniversaries} style={{ color: "white" }}>
                                        {anniversariesExpanded ? 'Show less...' : 'Show more...'}
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </>
                            :
                            <Card.Body>
                              No data available.
                            </Card.Body>
                          }
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </Col>
              {/* Employee Details || END */}

              {/* Calender || START */}
              <Col lg={4} sm={12} xs={12}>
                <Row>
                  <Container className='mt-1'>
                    <Col lg={12} sm={12} xs={12} className="text-center" style={{ borderRadius: "7px", backgroundColor: "rgb(99,115,132)" }}>
                      <h4 className="fw-semibold text-light p-2">Calendar</h4>
                    </Col>
                  </Container>
                  <Col lg={12} sm={12} xs={12} className='mt-1' style={{ borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px" }}>
                    <HolidaysCalender />
                  </Col>
                </Row>
              </Col>
              {/* Calender || END */}

            </Row>
          </Container>

        </>
        :
        <Container >
          <Row>
            <Col></Col>
            <Col lg={10} xs={12} sm={12}>
              <ShimmerTable row={6} col={3} />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      }

      <Container className='pb-5'> </Container>
      <ToastContainer />
      <Footer />
    </>
  )
}

export default Home