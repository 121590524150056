/**
 * @author: Abdul Pathan
 */
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { formatNumberWithDecimals } from '../common/helper';
import HRMSApi from '../../api/HRMSApi';
import Footer from '../layout/Footer';

const TaxDeductedSource = () => {
    const [body, setBody] = useState();
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        fetchTDSCalculator();
    }, []);

    const fetchTDSCalculator = async () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        let currentYear, nextYear, yearRange;
        if (currentMonth >= 4) {
            currentYear = currentDate.getFullYear();
            nextYear = currentYear + 1;
        } else {
            currentYear = currentDate.getFullYear() - 1;
            nextYear = currentYear + 1;
        }
        yearRange = `${currentYear}-${nextYear}`;
        setSelectedYear(yearRange);

        if (yearRange) {
            const result = await HRMSApi.getTaxDeductedSource(yearRange);
            // console.log(result.records);
            if (result.success) {
                setBody(result.records);
            }
        }
    }

    // Select Financial Year
    const handleChange = (event) => {
        setSelectedYear(event.target.value);
    }

    // TDS calculation
    const onClickTDSCalculation = async () => {
        setBody();
        if (selectedYear) {
            const result = await HRMSApi.getTaxDeductedSource(selectedYear);
            if (result.success) {
                setBody(result.records);
            }
        }
    }

    return (
        <>
            {/* HEADER */}
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={7} sm={12} xs={12}>
                        <div className="p-2 text-center" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "16px" }}>Tax Calculation Report</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Container className='mt-3'>
                <Row>
                    <Col></Col>
                    <Col lg={7} sm={12} xs={12}>
                        <Row>
                            <Col lg={6} sm={6} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Select Financial Year</Form.Label>
                                    <Form.Select
                                        placeholder="Select Financial Year"
                                        aria-label="Default select example"
                                        name="financial_year"
                                        value={selectedYear}
                                        onChange={handleChange}
                                    >
                                        <option value="2022-2023">2022-2023</option>
                                        <option value="2023-2024">2023-2024</option>
                                        <option value="2024-2025">2024-2025</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={6} xs={6}>
                                <button className='btn btn-outline-primary float-end mb-2 mt-4' onClick={onClickTDSCalculation}>
                                    Calculator TDS
                                </button>
                            </Col>
                        </Row>

                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {/* Tax Calculation Report  */}
            {body ? (
                <Container className='mt-3 mb-5'>
                    <Row>
                        <Col></Col>
                        <Col lg={7} sm={12} xs={12}>
                            <Row className='mx-1 mb-5' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                                <div className="card-body mx-2 mb-3">
                                    <div className="table-responsive">
                                        <table className="table mb-0 mt-2">
                                            <tbody>
                                                <tr >
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Annual Gross Salary</td>
                                                    <td className='text-end'>
                                                        {body.grossSalary == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.grossSalary)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Additions</td>
                                                    <td></td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>Incentives</td>
                                                    <td className='text-end'>
                                                        {body.totalIncentive == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.totalIncentive)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: "20px" }}>Over Time</td>
                                                    <td className='text-end'>
                                                        {body.totalOvertime == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.totalOvertime)}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Deductions</td>
                                                    <td></td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>Leaves</td>
                                                    <td className='text-end'>
                                                        {body.totalLeaves == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.totalLeaves)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: "20px" }}>Other Deduction</td>
                                                    <td className='text-end'>
                                                        {body.otherDeduction == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.otherDeduction)}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Taxable Salary</td>
                                                    <td className='text-end'>
                                                        {body.totalIncome == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.totalIncome)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: "20px" }}>(-) Standard Deduction</td>
                                                    <td className='text-end'>
                                                        {body.stndDeduction == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.stndDeduction)}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Taxable Income</td>
                                                    <td className='text-end'>
                                                        {body.totalIncome == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.totalIncome - body.stndDeduction)}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Declaration in 12BB</td>
                                                    <td></td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>Regime</td>
                                                    <td className='text-end'>
                                                        {body.regime}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 80 C</td>
                                                    <td className='text-end'>
                                                        {body.bb80C == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb80C)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 80 D</td>
                                                    <td className='text-end'>
                                                        {body.bb80D == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb80D)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 80 CCD</td>
                                                    <td className='text-end'>
                                                        {body.bb80CCD == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb80CCD)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) HRA</td>
                                                    <td className='text-end'>
                                                        {body.bbHRA == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bbHRA)}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 24 (b)</td>
                                                    <td className='text-end'>
                                                        {body.bb24b == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb24b)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 80 G</td>
                                                    <td className='text-end'>
                                                        {body.bb80G == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb80G)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ paddingLeft: "20px" }}>(-) 80 GGC</td>
                                                    <td className='text-end'>
                                                        {body.bb80GGC == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bb80GGC)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: "20px" }}>(-) Other</td>
                                                    <td className='text-end'>
                                                        {body.bbother == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.bbother)}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: body.taxRanges.length === 0 ? "" :  "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Net Taxable Income</td>
                                                    <td className='text-end'>
                                                        {body.netTaxableIncome == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.netTaxableIncome)}
                                                    </td>
                                                </tr>

                                                {
                                                    body?.taxRanges.map((item) => (
                                                        <>
                                                            {
                                                                item.orderNo === 1 ?
                                                                    <tr style={{ borderBottom: "hidden" }}>
                                                                        <td >Up to {formatNumberWithDecimals(item.uptoRange)} </td>
                                                                        <td className='text-end'>
                                                                            {item.uptoAmount == null ? 'Rs. 0.00' : formatNumberWithDecimals(item.uptoAmount)}
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    item.orderNo === body.taxRanges.length ?
                                                                        <tr style={{ borderBottom : "" }}>
                                                                            <td >Next {formatNumberWithDecimals(item.uptoRange)} @ {item.uptoPct}%</td>
                                                                            <td className='text-end'>
                                                                                {item.uptoAmount == null ? 'Rs. 0.00' : formatNumberWithDecimals(item.uptoAmount)}
                                                                            </td>
                                                                        </tr>
                                                                        : <tr style={{ borderBottom : "hidden" }}>
                                                                            <td >Next {formatNumberWithDecimals(item.uptoRange)} @ {item.uptoPct}%</td>
                                                                            <td className='text-end'>
                                                                                {item.uptoAmount == null ? 'Rs. 0.00' : formatNumberWithDecimals(item.uptoAmount)}
                                                                            </td>
                                                                        </tr>
                                                            }
                                                        </>
                                                    ))
                                                }

                                                <tr style={{borderBottom : "hidden"}}>
                                                    <td>Tax before Cess</td>
                                                    <td className='text-end'>
                                                        {body.taxBeforeLess == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.taxBeforeLess)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >(+) Cess @{formatNumberWithDecimals(body.cessPct)}%</td>
                                                    <td className='text-end'>
                                                        {body.cessAmt == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.cessAmt)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td >Total Tax After Cess</td>
                                                    <td className='text-end'>
                                                        {body.taxAfterLess == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.taxAfterLess)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >Tax Deducted Till Month</td>
                                                    <td className='text-end'>
                                                        {body.paidTDS == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.paidTDS)}
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "hidden" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>Tax Suppose To Be Deducted In Next Months</td>
                                                    <td className='text-end'>
                                                        {body.remainingTDS == null ? 'Rs. 0.00' : formatNumberWithDecimals(body.remainingTDS)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            ) :
                <div className="">
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            }

            <Container className='mt-5 mb-5'></Container>
            <Footer />
        </>
    )
}

export default TaxDeductedSource
