/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import HRMSApi from "../../api/HRMSApi";

const SendOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userName, setUserName] = useState({});
  const [verifyOTP, setVerifyOTP] = useState("");
  const [recievedOTP, setRecievedOTP] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpexpired, setotpexpired] = useState(false);
  const [showEmail, setShowEmail] = useState();

  // console.log('location obj', location?.state)

  useEffect(() => {
    let decodedToken = jwt_decode(location?.state);
    // console.log('decodedToken =>', decodedToken)
    if (decodedToken?.email && decodedToken?.userName) {
      let email = decodedToken?.email;
      let parts = email.split("@");
      let maskedEmail = "******@" + parts[1];
      setShowEmail(maskedEmail);
      setUserName(decodedToken?.userName);
      generateOTP(decodedToken?.userName);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(60);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); // Run this effect every 1000ms (1 second)
    return () => {
      clearInterval(interval);
    };
  }, [seconds]); // Re-run this effect whenever 'seconds' changes

  // OTP generate
  const generateOTP = async (name) => {
    setMinutes(1);
    setSeconds(30);
    setotpexpired(false);

    let decodedToken = jwt_decode(location?.state);
    // console.log('decodedToken =>obj', decodedToken)
    let obj = {
      email: decodedToken.email,
      name: decodedToken.userName,
    };

    // const obj = { email: location?.state?.email, name: name }
    const sendVerificationCode = await HRMSApi.sendEmailOTPVerification(obj);
    //console.log("sendVerificationCode", sendVerificationCode);
  };

  // change input filed OTP
  const handleChangeOTP = async (e) => {
    const inputValue = e.target.value;
    setVerifyOTP(inputValue);
  };

  const handleSubmit = async (e) => {
    //console.log('verifyOTP',verifyOTP)
    e.preventDefault();
    let decodedToken = jwt_decode(location?.state);
    if (seconds === 0 && minutes === 0) {
      //console.log("call exprie");
      setotpexpired(true);
      var obj = {
        email: decodedToken.email,
        currentotp: verifyOTP,
        otpexpired: true,
      };
      //console.log("obj ", obj);
      let verificationOtp = await HRMSApi.otpverification(obj);
      //console.log("verificationOtp", verificationOtp);
      //console.log(verificationOtp)
      if (verificationOtp.success === false) {

        setShow(true);
        setErrorMessage(verificationOtp.message);
        return;
      }
    }
    //console.log("call verify", verifyOTP,otpexpired);

    if (verifyOTP && otpexpired === false) {
      let obj = {
        email: decodedToken.email,
        currentotp: verifyOTP,
        otpexpired: otpexpired,
      };
      let verificationOtp = await HRMSApi.otpverification(obj);
      //console.log("verificationOtp", verificationOtp);
      if (verificationOtp.success === true) {
        const authToken = location?.state;
        if (authToken) {
          navigate("/update_password", { state: authToken });
        }
      } else {
        //console.log('Incorrect OTP entered');
        setShow(true);
        setErrorMessage("OTP is not correct.");
        return false;
      }
    } else {
      setShow(true);
      setErrorMessage("Required field missing.");
    }
  };

  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <Card
              style={{
                border: "0px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row className="pt-3">
                <Col lg={12}>
                  <div className="text-center mb-3 mt-3">
                    <img
                      src="indihrmslogo.jpg"
                      style={{ width: "180px" }}
                      className="mb-2"
                      alt=""
                    />
                  </div>
                </Col>

                <Col lg={12}>
                  <Row className="text-center mx-3">
                    <Col lg={12} sm={12} xs={12}>
                      <span style={{ color: "grey", fontSize: "medium" }}>
                        Enter your verification code
                      </span>
                    </Col>

                    <Col className="mt-3" lg={12} sm={12} xs={12}>
                      <span style={{ fontSize: "13px" }}>
                        Please enter one-time password to verify your account.
                      </span>
                    </Col>
                    <Col className="mt-3" lg={12} sm={12} xs={12}>
                      <span style={{ fontSize: "13px" }}>
                        A code has been sent to {showEmail}
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={12}>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mx-3">
                      <Col lg={12} sm={12} xs={12} className="mt-3 text-center">
                        <Alert
                          variant="danger"
                          show={show}
                          className="error-alert"
                        >
                          {errorMessage}
                        </Alert>
                      </Col>

                      <Col lg={12} sm={12} xs={12}>
                        <Form.Group className="my-2">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicFirstName"
                          ></Form.Label>
                          <Form.Control
                            type="number"
                            name="otp"
                            onChange={handleChangeOTP}
                            style={{ borderRadius: "20px" }}
                            placeholder="Enter OTP"
                          />
                        </Form.Group>
                      </Col>

                      {seconds > 0 || minutes > 0 ? (
                        <>
                          <Col
                            className="text-center mt-2"
                            lg={12}
                            sm={12}
                            xs={12}
                          >
                            Time Remaining:
                            <span className="mx-2" style={{ fontWeight: 600 }}>
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                            <br></br>

                          </Col>

                          <Col className="mt-2" lg={12} sm={12} xs={12}>
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                width: "100%",
                                borderRadius: "20px",
                                backgroundColor: "#266D8D",
                                color: "white",
                              }}
                            >
                              Verify
                            </button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col className="text-center" lg={12} sm={12} xs={12}>
                            <i>Didn't receive code?</i>
                            <button
                              className="btn p-0 mx-1"
                              onClick={() => generateOTP(userName)}
                              disabled={seconds > 0 || minutes > 0}
                              style={{
                                color:
                                  seconds > 0 || minutes > 0 ? "blue" : "blue",
                                borderRadius: "5px",
                              }}
                            >
                              Resend OTP
                            </button>
                          </Col>
                          <Col className="mt-2" lg={12} sm={12} xs={12}>
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                width: "100%",
                                borderRadius: "20px",
                                backgroundColor: "#266D8D",
                                color: "white",
                              }}
                            >
                              Verify
                            </button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
    </>
  );
};

export default SendOTP;