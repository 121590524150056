/**
 * @author: Abdul Pathan
 */

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HRMSApi from '../../api/HRMSApi';
import Footer from '../layout/Footer';
import jwtDecode from 'jwt-decode';
import * as containts from '../../constants/CONSTANT';
import { useNavigate } from 'react-router-dom';

const HandBook = () => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        let token = localStorage.getItem('token');
        if (jwtDecode(token).contactDetails.Name === containts.College_Name) navigate("/");
        fetchHandbookPDF();
        detectDeviceType();
    }, []);

    // Fetch Employee Handbook PDF URL
    const fetchHandbookPDF = async () => {
        try {
            const result = await HRMSApi.getEmployeeHandBook();
            if (result.success && result.record?.Id) {
                const handbookPDFUrl = await HRMSApi.getHandBookPDF(result.record.Id);
                setPdfUrl(handbookPDFUrl);
            }
        } catch (error) {
            console.error('Error fetching Employee Handbook PDF:', error);
        }
    }

    // Detect device type
    const detectDeviceType = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent));
    }

    return (
        <>
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10} xs={12} sm={12}>
                        <div className="p-2 text-center" style={{ backgroundColor: "#ffffff", borderRadius: "5px", }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>
                                Employee Handbook
                            </span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>


            {pdfUrl ? (
                <Container className='mt-4 mb-5'>
                    <Row>
                        <Col></Col>
                        <Col lg={10} xs={12} sm={12}>
                            {isMobile ? (
                                <div className='text-center'>
                                    <a href={pdfUrl} download="Employee_Handbook.pdf">Download Employee Handbook</a>
                                </div>
                            ) : (
                                <>
                                    <iframe
                                        title='Employee Handbook'
                                        src={pdfUrl}
                                        width="100%"
                                        height="600px"
                                    ></iframe>
                                </>
                            )}
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            ) : (
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            )}

            <Container style={{ paddingBottom: "40px" }}> </Container>
            <Footer />
        </>
    )
}

export default HandBook
