import * as constants from '../constants/CONSTANT';
import authApi from "./authApi";
import axios from 'axios';
// import jwt_decode from "jwt-decode";

const HRMSApi = {



  // added By
  async updateUser(user) {
    //cosole.log(user)
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );
    return await response.json();
  },


  // ************************************** INDI-HRMS-APIS ************************************************



  // *********************************Access Salesforce Contact Record according to email id ****************************
  async getContactRecordByEmail() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/useremail/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //cosole.log('###result', result)
    if (result) {
      return result;
    }
    return null;
  },

  // ************************ Access All the payroll list according to login user id **************************
  // async getPayRollRecord() {
  //   const token = localStorage.getItem("token");
  //   let response = await fetch(constants.API_BASE_URL + "/api/salesforce/payroll", {
  //     method: "GET",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: token,
  //     },
  //   });

  //   if (response.status === 401) {
  //     authApi.logout();
  //   }
  //   const result = await response.json();
  //   console.log('result', result)
  //   if (result) {
  //     return result;
  //   }
  //   return null;
  // },

  // ************************* Access Payroll by payroll id ****************************
  async getPayRollRecordById(payrollId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/payrollId/" + payrollId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // **********************Access Attachement Record According to Attachemetid ************************
  // async getAttachmentRecord(attachementId) {
  //   const token = localStorage.getItem("token");
  //   let response = await fetch(constants.API_BASE_URL + "/api/salesforce/attachmentId/" + attachementId, {
  //     method: "GET",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: token,
  //     },
  //   });

  //   if (response.status === 401) {
  //     authApi.logout();
  //   }
  //   const result = await response.json();
  //   if (result) {
  //     return result;
  //   }
  //   return null;
  // },

  async attchementurl(id) {
    const token = localStorage.getItem("token");

    try {
      let response = await fetch(constants.API_BASE_URL + "/api/salesforce/downloadpdf/" + id, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: token,
        },
      });

      if (response.status === 401) {
        authApi.logout();
        return null;  // Returning null when unauthorized
      }

      if (!response.ok) {
        return null;
      }

      // Assuming the server is returning the PDF as a binary response
      const blob = await response.blob();

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(blob);

      return pdfUrl;
    } catch (error) {
      return null;
    }
  },
  // ***************************** Access Image url **************************
  async getImage() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/employee/image/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ************************** Access All the holiday dates *****************************
  async getHolidays() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/holidays/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  //************************ */ Access All the project list *************************
  async getProjectRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/projects", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ***************************Access All the working hours according to start and end date **************
  async getWorkingHours(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/workinghours/" + '?startDate=' + data.startDate + '&endDate=' + data.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ********************************* Create New timesheet working hour project record ************************
  async createProjectRecords(records) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/workinghours",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(records),
      });
    return await response.json();
  },
  // send email for verification code generate || added by Abdul Pathan || 09 January 2024
  async sendEmailOTPVerification(request) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/request_otp",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(request),
      });
    return await response.json();
  },

  // ***********************************************************

  //from salesforce Get Payroll Records Based on Contact ID || Added by Abdul Pathan || 11 January 2024
  async getPaySlipRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/pay_slip", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  // Get Payroll Records Based on Id || Added by Abdul Pathan || 11 January 2024
  async getPaySlipViewRecord(recordId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/pay_slip_view/" + recordId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
  },

  // update user password || Added by Abdul Pathan || 13 January 2024
  async updateUserPassword(user) {
    // const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/salesforce/update_password",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": user.authToken,
          // "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );
    return await response.json();
  },
  async getTimesheetRecordsMonth(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/timesheetmonthdata/" + '?startDate=' + data.startDate + '&endDate=' + data.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
  async otpverification(request) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/otpverify",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(request),
      });
    return await response.json();
  },


  /***************** LEAVES SECTION START || Added by Abdul Pathan **********************************************/
  // LEAVES DASHBOARD RECORD
  async getLeavesDashboardRecord() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves/counts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // GET LEAVES RECORDS
  async getAllLeavesRecords(obj) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves/apply?startDate=" + obj.startDate + '&endDate=' + obj.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },


  // INSERT AND UPDATE LEAVES RECORD
  async createLeavesRecords(records) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves/apply",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(records),
      });
    return await response.json();
  },


  // LEAVES TABLE RECORDS
  async getLeavesTableData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // LEAVES UPDATE RECORD
  async updateLeaveRecord(record) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(record),
      });
    return await response.json();
  },

  /****************************************LEAVES SECTION END***********************************************************/


  /****************************************EMPLOYEE HANDBOOK SECTION START***********************************************************/
  // get employee handbook id
  async getEmployeeHandBook() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/handbook", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // employee handbook pdf
  async getHandBookPDF(id) {
    const token = localStorage.getItem("token");
    try {
      let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/handbook/downloadpdf/" + id, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: token,
        },
      });

      if (response.status === 401) {
        authApi.logout();
        return null;  // Returning null when unauthorized
      }
      if (!response.ok) {
        console.error(`Failed to fetch PDF. Status: ${response.status}`);
        return null;
      }

      const blob = await response.blob();
      // const headers = new Headers(response.headers);
      // headers.set('Content-Disposition', `attachment; filename="Employee_Handbook"`);
      const pdfUrl = URL.createObjectURL(blob);
      // return { pdfUrl, headers };
      return pdfUrl;
    } catch (error) {
      console.error('Error during PDF download:', error);
      return null;
    }
  },
  /****************************************EMPLOYEE HANDBOOK SECTION END***********************************************************/



  /***************** Tax Calculation Report START || Added by Abdul Pathan **********************************************/

  async getTaxDeductedSource(financialYear) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/taxDeductedSource?fyyear=" + financialYear, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
  /******************************** Tax Calculation Report || END ***************************************************/


  /***************** View Tax Form-16 || START || Added by Abdul Pathan **********************************************/
  // Tax Form-16 records
  async getTaxForm16Records() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/taxform_16", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // Tax Form-16 PDF download
  async getTaxForm16PDF(id) {
    // console.log('URLL', encodeURIComponent(url))
    const token = localStorage.getItem("token");
    try {
      let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/taxform_16/pdfdownload/" + id, {
        method: "GET",
        mode: "cors",
        headers: { Authorization: token }
      });

      if (response.status === 401) {
        authApi.logout();
        return null;  // Returning null when unauthorized
      }
      if (!response.ok) {
        console.error(`Failed to fetch PDF. Status: ${response.status}`);
        return null;
      }
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      return pdfUrl;
    } catch (error) {
      console.error('Error during PDF download:', error);
      return null;
    }
  },
  /******************************** Tax Form-16 || END ***************************************************/

  /***************** Tax 12BB Form || START || Added by Abdul Pathan **********************************************/
  // FETCH TAX 12BB FORM RECORDS
  async getTaxForm12BBRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/tax_12BB_form", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // CREATE TAX 12BB FORM RECORDS
  async createTax12BBFormRecords(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/tax_12BB_form",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },
  /***************** Tax 12BB Form-12 || END **********************************************/
  // fetch all users 

  async getAllUsers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/leaves/members", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  /******************************** Feedback || START ***************************************************/
  async getFeedbackRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/feedback", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async insertFeedbackRecord(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/feedback",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },
  /******************************** Feedback || END ***************************************************/

  //*******************Employee Update ||START************************************************************* */ 
  // update emp record
  async updateEmployeeRecords(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/employee",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },
  // update emp image
  async insertImage(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/employee/image",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },

  async getEmployeeRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indi_hrms/employee", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
  //*******************Employee Update ||END************************************************************* */ 


  //************************************************ Leave Approval || Start *********************************************//
  async getEmployeeLeavesRequest() {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const result = await axios.get(constants.API_BASE_URL + '/api/indi_hrms/leaveapproval', {
          method: "GET",
          mode: "cors",
          headers: { "Content-Type": "application/json", Authorization: token },
        });
        if (result.status === 401) {
          authApi.logout();
        }
        return result;
      } catch (error) {
        throw error;
      }
    }
  },

  async approveOrRejectLeaveByAdmin(approvOrRejectRecord) {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const result = await fetch(constants.API_BASE_URL + '/api/indi_hrms/leaveapproval', {
          method: "POST",
          mode: 'cors',
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({ leaveRecords: approvOrRejectRecord })
        });
        if (result.status === 401) {
          authApi.logout();
        }
        return (await result.json());
      } catch (error) {
        throw error;
      }
    }
  },

  // ********************** Fetch Medical form *******************
  async getEmployeeMedicalForm(employeeid) {
    const token = localStorage.getItem('token');
    if (token) {
      try{
        const result = await fetch(constants.API_BASE_URL + "/api/indi_hrms/employeemedicalcard/" + employeeid, {
          method: "GET",
          mode: 'cors',
          headers: { "Content-Type": "application/json", Authorization: token },
        })
        if (result.status === 401) {
          authApi.logout();
        }
        return (await result.json());         
      }catch(error){
        throw error;
      }
    }
  }
}
export default HRMSApi