/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
// import htmlToPdfmake from "html-to-pdfmake";
// import pdfMake from "pdfmake";
// import * as constants from "../../constants/CONSTANT";
import moment from 'moment';
import { useNavigate, useLocation } from "react-router-dom";
import Footer from '../layout/Footer';
import { formatNumberWithDecimals, numberToEnglishWord } from '../common/helper';
import { ShimmerTable } from 'react-shimmer-effects';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HRMSApi from "../../api/HRMSApi";
import jwt_decode from "jwt-decode";
import * as constants from "../../constants/CONSTANT";


const PaySlipsView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [payrollRecords, setPayrollRecords] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem("token");
    let decodedToken = jwt_decode(token);
    const [accountName, setAccountName] = useState();

    useEffect(() => {
        setAccountName(decodedToken.contactDetails.Name)
        fetchPayrollRecords();
    }, []);

    const fetchPayrollRecords = async () => {
        if (location?.state?.payrollId) {
            const responce = await HRMSApi.getPaySlipViewRecord(location?.state?.payrollId);
            // console.log('####responce', responce)
            if (responce.success) {
                setPayrollRecords(responce?.records);
            } else {
                setPayrollRecords();
            }
        }
    }
    // PaySlip PDF download
    const handleClickPaySlipDownload = async () => {
        try {
            if (location?.state?.payrollId) {
                setIsLoading(true);
                const serverDownloadUrl = await HRMSApi.attchementurl(location?.state?.payrollId);
                setIsLoading(false)
                const newWindow = window.open(serverDownloadUrl, '_blank');
                if (newWindow) {
                    newWindow.addEventListener('load', () => {
                        const downloadLink = newWindow.document.createElement('a');
                        downloadLink.href = serverDownloadUrl;
                        let attachementname = location?.state?.attachmentName
                        downloadLink.download = attachementname;
                        downloadLink.click();
                    });
                } else {
                    console.error('Failed to open a new window to download the file.');
                }
            } else {
                toast.error("Attachment not found.", { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error during PDF download:', error);
        }
    }
    // Redirect PaySlips page
    const handleArrowLeftClick = () => {
        navigate(`/payslips`);
    }
    return (
        <>
            {payrollRecords?.Salary_Date__c ? (
                <>
                    <Container className='mt-5'>
                        <Row className='p-3 mx-3' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                            <Col lg={10} sm={8} xs={8}>
                                <span className='fw-semibold' style={{ color: "#605C68", fontSize: "15px" }}>Payslip for the period of</span>
                                <span className='fw-bold mx-2' style={{ color: "#C0A8FC", fontSize: "15px" }}>
                                    {moment(payrollRecords?.Salary_Date__c).format('MMMM YYYY')}
                                </span>
                            </Col>
                            <Col lg={2} sm={4} xs={4} className="text-end">
                                <span style={{ color: "#B7C1E8" }}>
                                    <i className="fa-solid fa-download mx-3 pointer" title='Download' style={{ fontSize: "20px", cursor: "pointer" }} onClick={handleClickPaySlipDownload}></i>
                                    <i className="fa-solid fa-arrow-left pointer" title='Back' style={{ fontSize: "20px", cursor: "pointer" }} onClick={handleArrowLeftClick}></i>
                                </span>
                            </Col>
                        </Row>
                    </Container>

                    {!isLoading ? (
                        <>
                            {/* Section Box */}
                            <Container className='mt-3'>
                                <Row className='p-2 mx-3' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                                    <Col lg={12} >
                                        <Row>
                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2' style={{ background: "#E1F0FE", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollRecords?.Total_Earning__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollRecords?.Total_Earning__c)}</h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Earning</p>
                                                </div>
                                            </Col>

                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2 mx-1' style={{ background: "#FEF3DE", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollRecords?.Total_Deduction__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollRecords?.Total_Deduction__c)}</h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Deductions</p>
                                                </div>

                                            </Col>

                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2 mx-1' style={{ background: "#C9F6F5", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollRecords?.Net_Payable_Amount__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollRecords?.Net_Payable_Amount__c)}</h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Netpayable Income</p>
                                                </div>

                                            </Col>

                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2 mx-1' style={{ background: "#FEE2E5", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>
                                                        {moment(payrollRecords?.Salary_Date__c).format('MMMM YYYY')}
                                                    </h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Salary Period</p>
                                                </div>
                                            </Col>

                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2 mx-1' style={{ background: "#EEE5FE", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollRecords?.Total_Leaves__c) == null ? '0' : (payrollRecords?.Total_Leaves__c)?.toFixed(1)}</h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Leaves</p>
                                                </div>
                                            </Col>

                                            <Col lg={2} sm={12} xs={12} className='p-1'>
                                                <div className='text-center p-2' style={{ background: "#E2EEFF", borderRadius: "5px" }}>
                                                    <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollRecords?.Common_Leaves__c) == null ? '0' : (payrollRecords?.Common_Leaves__c)?.toFixed(1)}</h4>
                                                    <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Common Leaves</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                            <Container className='mt-2'>
                                <Row>
                                    <Col lg={6} sm={12} xs={12}>
                                        <Row className='mx-3 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px" }}>
                                            <span className="p-2 mx-1  mb-1" style={{ fontSize: "14px" }}>Earnings</span>
                                            <div className="card-body mx-2 mb-4">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <tbody className='p-1'>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>Basic</td>
                                                                <td className='text-end'>
                                                                    {payrollRecords?.Employee_Salary__r?.Basic__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Employee_Salary__r?.Basic__c / 12)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>HRA</td>
                                                                <td className='text-end'>
                                                                    {payrollRecords?.Employee_Salary__r?.HRA__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Employee_Salary__r?.HRA__c / 12)}
                                                                </td>
                                                            </tr>
                                                            {accountName === constants?.Company_Name ?
                                                                <>
                                                                    <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                        <td>Internet Allowance</td>
                                                                        <td className='text-end'>
                                                                            {payrollRecords?.Employee_Salary__r?.Internet_Allow__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Employee_Salary__r?.Internet_Allow__c / 12)}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                        <td >LTA</td>
                                                                        <td className="text-end">
                                                                            {payrollRecords?.Employee_Salary__r?.LTA__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Employee_Salary__r?.LTA__c / 12)}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                    <td >Conveyance Allowance</td>
                                                                    <td className="text-end">
                                                                        {payrollRecords?.Conveyance_Allowance__c == null ? "Rs. 0.00" : formatNumberWithDecimals(payrollRecords?.Conveyance_Allowance__c)}
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Special Allowance</td>
                                                                <td className="text-end">
                                                                    {payrollRecords?.Employee_Salary__r?.Special_Allow__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Employee_Salary__r?.Special_Allow__c / 12)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>Leave Encashment</td>
                                                                <td className='text-end'>
                                                                    {(payrollRecords?.Leave_Encashment__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Leave_Encashment__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>Over Time</td>
                                                                <td className='text-end'>
                                                                    {(payrollRecords.Overtime__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Overtime__c)}
                                                                </td>
                                                            </tr>

                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Other Convenyance</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords.Other_Convenience__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Other_Convenience__c)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="card-footer d-flex justify-content-between bg-white pt-3 mt-2 pl-4 pr-4"
                                                style={{ borderTop: "1px solid #c9bcbc", paddingBottom: "15px", color: "dimgrey" }}>
                                                <Col lg={9} sm={7} xs={7}>
                                                    <span className='mx-3 fw-semibold'>Total Earning</span>
                                                </Col>
                                                <Col lg={3} sm={5} xs={5}>
                                                    <h6 className='mx-3 text-end fw-semibold' style={{ color: "#796C9A" }}>
                                                        {payrollRecords.Total_Earning__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords.Total_Earning__c)}
                                                    </h6>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>

                                    <Col lg={6} sm={12} xs={12} style={{ height: "29rem" }}>
                                        <Row className='mx-3 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px" }}>
                                            <span className="p-2 mx-1" style={{ fontSize: "14px" }}>Deductions</span>
                                            <div className="card-body mx-2" style={{ paddingBottom: payrollRecords.Employee__r.Branch__c?.toLowerCase() === 'bangalore' ? "0px" : "29px" }}>
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <tbody className='p-1'>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>E. S. I. C.</td>
                                                                <td className='text-end'>
                                                                    {payrollRecords?.ESIC_Employee__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.ESIC_Employee__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>P.F.</td>
                                                                <td className='text-end'>
                                                                    {payrollRecords?.PF_Employee__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.PF_Employee__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Loan</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords?.Loan__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Loan__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >T.D.S.</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords?.TDS__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.TDS__c)}
                                                                </td>
                                                            </tr>

                                                            {payrollRecords.Employee__r.Branch__c?.toLowerCase() === 'bangalore' && (
                                                                <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                    <td>Professional Tax</td>
                                                                    <td className="text-end">
                                                                        {payrollRecords?.Professional_Tax__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Professional_Tax__c)}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Leave Deduction</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords?.Leave_Deduction__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Leave_Deduction__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Advance</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords?.Advance__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Advance__c)}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >Other Deduction</td>
                                                                <td className="text-end">
                                                                    {(payrollRecords?.Other_Deduction__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Other_Deduction__c)}
                                                                </td>
                                                            </tr>
                                                            {
                                                                accountName === constants.Company_Name &&
                                                                <>
                                                                    <tr style={{ borderBottom: "hidden" }}><td></td></tr>
                                                                    <tr><td></td></tr>
                                                                </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="card-footer d-flex justify-content-between bg-white pt-3 pl-4 pr-4"
                                                style={{ borderTop: "1px solid #c9bcbc", paddingBottom: "15px", fontFamily: "Montserrat sansSerif", color: "dimgrey" }}>
                                                <Col lg={9} sm={7} xs={7}>
                                                    <span className='mx-3 fw-semibold'>Total Deduction</span>
                                                </Col>
                                                <Col lg={3} sm={5} xs={5}>
                                                    <h6 className='mx-3 text-end fw-semibold' style={{ color: "#796C9A" }}>
                                                        {payrollRecords?.Total_Deduction__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords?.Total_Deduction__c)}
                                                    </h6>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                            <Container className='mt-3 pb-5'>
                                <Row className='mx-3 p-2 row text-center' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                                    <Col lg={12} sm={12} xs={12}>
                                        <span className='mx-3' style={{ color: "#796C9A", fontSize: "18px", fontWeight: "700" }}>
                                            Net Payable Income:
                                        </span>
                                        <span style={{ color: "#796C9A", fontSize: "18px" }}>
                                            {payrollRecords.Net_Payable_Amount__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollRecords.Net_Payable_Amount__c)} ({payrollRecords.Net_Payable_Amount__c == null ? 'Zero' : numberToEnglishWord(payrollRecords.Net_Payable_Amount__c)} Rupees)
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className='mt-5'></Container>
                        </>
                    ) : (
                        <div>
                            <div className="sk-cube-grid">
                                <div className="sk-cube sk-cube1"></div>
                                <div className="sk-cube sk-cube2"></div>
                                <div className="sk-cube sk-cube3"></div>
                                <div className="sk-cube sk-cube4"></div>
                                <div className="sk-cube sk-cube5"></div>
                                <div className="sk-cube sk-cube6"></div>
                                <div className="sk-cube sk-cube7"></div>
                                <div className="sk-cube sk-cube8"></div>
                                <div className="sk-cube sk-cube9"></div>
                            </div>
                        </div>
                    )}
                </>
            ) :
                <Row>
                    <Col></Col>
                    <Col lg={10} className='text-center'>
                        <ShimmerTable row={5} col={5} />
                    </Col>
                    <Col></Col>
                </Row>
            }

            <ToastContainer />
            <Footer />
        </>
    )
}

export default PaySlipsView
