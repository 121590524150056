/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Card, Col, Row } from "react-bootstrap";
import HRMSApi from "../../api/HRMSApi";
import moment from 'moment';
import { ShimmerTable } from "react-shimmer-effects";

const HolidaysCalender = () => {
    const [holidayDates, setHolidayDates] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    // const [currentMonth, setCurrentMonth] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchHolidayRecords();
    }, []);

    const fetchHolidayRecords = async () => {
        try {
            const response = await HRMSApi.getHolidays();
            if (response.success) {
                const newHolidays = response?.records.map(holiday => {
                    let newName = holiday.Name;
                    if (newName.startsWith("(All) - ")) {
                        newName = newName.replace("(All) - ", "");
                    } else if (newName.startsWith("(College) - ")) {
                        newName = newName.replace("(College) - ", "");
                    }
                    return {
                        ...holiday,
                        Name: newName
                    };
                });
                setHolidayDates(newHolidays);
            }
        } catch (error) {
            console.error("Error fetching holiday records:", error);
        } finally {
            setLoading(false);
        }
    }

    const tileClassName = ({ date }) => {
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        var formattedDate = year + "-" + month + "-" + day;

        // console.log('holidayDatesholidayDates', holidayDates)

        const isHoliday = holidayDates.some((holidayDate) => formattedDate === holidayDate?.ActivityDate);

        const isCurrentDate = date.toISOString().split("T")[0] === currentDate.toISOString().split("T")[0];

        return isHoliday ? "highlighted-blue" : isCurrentDate ? "highlighted-current-date" : "";
    };

    const holidaysByMonth = {};

    holidayDates.forEach(item => {
        const activityDate = new Date(item?.ActivityDate);
        const month = activityDate.getMonth();

        if (!holidaysByMonth[month]) {
            holidaysByMonth[month] = [];
        }
        holidaysByMonth[month].push(item);
    });

    const showNextMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    };

    const showPreviousMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
    };

    return (
        <>
            <Calendar tileClassName={tileClassName} value={currentDate} style={{ height: "40% !important" }} />

            <Row className='mt-2' style={{ margin: "1px" }}>
                <Col lg={12} sm={12} xs={12} className="text-center" style={{ borderRadius: "7px", backgroundColor: "rgb(99,115,132)" }}>
                    <h4 className="fw-semibold text-light p-1">Holidays</h4>
                </Col>
            </Row>

            <div className="mt-2" style={{ backgroundColor: "white", borderRadius: "7px" }}>
                <Card>
                    <Row className="text-center">
                        <Col lg={2} sm={4} xs={4} onClick={showPreviousMonth}>
                            <div className="mt-3" style={{ color: "rgb(76,130,213)", cursor: 'pointer' }}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                        </Col>

                        <Col lg={8} sm={4} xs={4} >
                            <div className="mt-2" style={{ color: "rgb(76,130,213)" }}>
                                <span className="fw-semibold" style={{ fontSize: "13px" }}>
                                    {moment().month(currentMonth).format('MMMM')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={2} sm={4} xs={4} onClick={showNextMonth}>
                            <div className="mt-3" style={{ color: "rgb(76,130,213)", cursor: 'pointer' }}>
                                <i className="fa-solid fa-arrow-right"></i>
                            </div>
                        </Col>
                    </Row>
                    <hr></hr>

                    {loading ? (
                        <ShimmerTable row={1} col={1} />
                    ) : holidaysByMonth[currentMonth]?.length ? (
                        holidaysByMonth[currentMonth]?.map((item, index) => (
                            <div key={index} className="accordion">
                                <Row className="mx-4">
                                    <Col lg={6} sm={6} xs={6}>
                                        <div className="my-2 mx-3 d-flex border-2" style={{ color: "rgb(76,130,213)" }}>
                                            {moment(item?.ActivityDate).format('DD MMMM YYYY')}
                                        </div>
                                    </Col>
                                    <Col lg={6} sm={6} xs={6}>
                                        <div className="my-2 mx-3 d-flex border-2" style={{ color: "rgb(76,130,213)" }}>
                                            {item?.Name}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    ) : (
                        <div className="accordion">
                            <Row className="text-center">
                                <Col lg={12} sm={12} xs={12} className="mb-3">
                                    <span>No holidays</span>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Card>
            </div>

        </>
    );
}
export default HolidaysCalender
