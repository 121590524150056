import React from 'react';
// { useState } from 'react';
import * as constants from '../constants/CONSTANT';

const authApi = {
  async login(email, password) {
    //cosole.log('constants.API_BASE_URL', constants.API_BASE_URL)
    let response = await fetch(constants.API_BASE_URL + "/api/auth/login", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    });

    const result = await response.json();
    //cosole.log('result--> in auth file->',result)
    if (result.success) {
      localStorage.setItem("token", result.authToken);
    }
    return result;
  },

  //check credentials || added by Abdul Pathan || 09 January 2024
  async credentialsVerification(email, password) {
    let response = await fetch(constants.API_BASE_URL + "/api/auth/verifyemail", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    });
    const result = await response.json();
    return result;
  },

  // update Password Email Verify || added by Abdul Pathan || 09 January 2024
  async updatePasswordEmailVerify(email) {
    let response = await fetch(constants.API_BASE_URL + "/api/auth/update_password_email_verify", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    });
    const result = await response.json();
    return result;
  },

  // async fetchMyImage() {
  //   const token = localStorage.getItem("token");
  //   let response = await fetch(constants.API_BASE_URL + "/api/auth/myimage", {
  //     method: "GET",
  //     //mode: "cors",
  //     headers: { "Authorization": token }
  //   }
  //   );
  //   if (response.status === 200) {
  //     const fileBody = await response.blob();
  //     return fileBody;
  //   } else {
  //     return null;
  //   }
  // },


  logout() {
    localStorage.clear();
    window.location.href = '/login';
  },

  async bs() {
    ////cosole.log("sssss");
    return "yes";
  },

}

export default authApi
