/**
 * @author: Abdul Pathan
 */
import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Form, Card, Table, Button, Badge } from 'react-bootstrap'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable'
import { ShimmerTable } from 'react-shimmer-effects';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../layout/Footer';
import HRMSApi from '../../api/HRMSApi';
import LeaveStatusUpdate from './LeaveStatusUpdate';
import moment from 'moment';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { MultiSelect } from "react-multi-select-component";
import jwt_decode from "jwt-decode";
import * as constants from "../../constants/CONSTANT";


const Leave = () => {
    const [isSpinner, setIsSpinner] = useState(true);
    const [isMainData, setIsMainData] = useState(false);
    const [leaveDashboardData, setLeaveDashboardData] = useState();

    const [rowData, setRowData] = useState({});
    const [mainData, setMainData] = useState([]);

    const [body, setBody] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [revokeModalShow, setRevokeModalShow] = useState(false);
    const [revokedLeaveRowData, setRevokedLeaveRowData] = useState({});

    const [selectMonthYear, setSelectMonthYear] = useState({ month: '', year: '' });

    const [base64String, setBase64String] = useState('');
    const [fileShowHide, setFileShowHide] = useState(false);
    const [fileDetails, setFileDetails] = useState({ name: '', type: '' });
    const fileInputRef = useRef(null);
    const formRef = useRef(null);
    const [fileError, setFileError] = useState('');
    const [fileErrorLimit, setFileErrorLimit] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [accountName, setAccountName] = useState();
    const token = localStorage.getItem("token");
    let decodedToken = jwt_decode(token);

    const [selectedusers, setselectedusers] = useState([]);
    const [tempselectionuser, settempselectionuser] = useState([]);
    const [extractedEmails, setextractedEmails] = useState([]);
    //console.log('selecteduser', selectedusers)
    // Function to format the date to 'DD-MM-YYYY' format
    const formatDate = (date) => {
        return moment(date).format('DD-MM-YYYY');
    };

    useEffect(() => {
        setAccountName(decodedToken.contactDetails.Name)
        fetchRecords();
    }, []);

    useEffect(() => {
        if (selectMonthYear.month && selectMonthYear.year) {
            const filteredData = tempData.filter(item => {
                const leaveDate = moment(item.leaveDate, 'DD-MM-YYYY'); // Parse the date string
                const leaveMonth = leaveDate.month() + 1; // Get the month (January is 0)
                const leaveYear = leaveDate.year(); // Get the year
                return leaveMonth === parseInt(selectMonthYear.month) && leaveYear === parseInt(selectMonthYear.year);
            });
            setBody(filteredData);
        } else {
            setBody(tempData);
        }
    }, [selectMonthYear]);

    // fetch leaves recorsd
    const fetchRecords = async () => {
        const leave_result = await HRMSApi.getLeavesDashboardRecord(); // Leaves Record
        // console.log('Leave Dashboard Record', leave_result);
        if (leave_result.success) {
            setLeaveDashboardData(leave_result?.record);
        }

        const leaveData = await HRMSApi.getLeavesTableData(); // Leaves Table Data
        if (leaveData.success) {
            const lData = leaveData?.records
            // Format the leaveDate in each leaveDetails object
            const formattedLeaveDetails = lData?.leaveDetails.map(detail => ({
                ...detail,
                leaveDate: formatDate(detail?.leaveDate)
            }));
            setBody(formattedLeaveDetails);
            setTempData(formattedLeaveDetails);
        }
        setIsSpinner(false)
    }

    // Get current date
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);

    // Format date to 'YYYY-MM-DD'
    const dateFormate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    // handle change
    const handleChange = (event) => {
        setRowData({ ...rowData, [event.target.name]: event.target.value });
        setIsMainData(true); // Set isMainData to true when the date changes
        setMainData([]);
        setFileError('');
        setFileErrorLimit('');

        if (event.target.name === 'reason') {
            if (event.target.value === 'Medical') {
                setFileShowHide(true);
            } else {
                if (fileShowHide) {
                    setFileShowHide(false);
                    setFileDetails({ name: '', type: '' });
                    setBase64String('');
                }
            }
        }
    }

    // fetch leaves records
    const handleNext = async () => {
        // Validate start_date and end_date
        if (!rowData.start_date || !rowData.end_date) {
            setIsMainData(false);
            toast.error("Start Date and End Date fields are required.", { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        const startDate = new Date(rowData.start_date);
        const endDate = new Date(rowData.end_date);

        if (endDate < startDate) {
            setIsMainData(false);
            toast.warning("End date must be greater than start date.", { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        if (!rowData.reason || !rowData.reason) {
            setIsMainData(false);
            toast.error("Reason is required.", { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        try {
            setIsMainData(true);

            let obj = {
                startDate: rowData.start_date,
                endDate: rowData.end_date
            }

            const response = await HRMSApi.getAllLeavesRecords(obj);
            // console.log('response', response)
            if (response.success) {
                setIsMainData(false);
                const updatedMainData = response.records?.leaveDetails.map(item => ({
                    ...item,
                    reason: rowData.reason,
                    comment: rowData.comment
                }));
                setMainData(updatedMainData);
            } else {
                setIsMainData(false);
                toast.error("Failed to fetch leaves records.", { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            setIsMainData(false);
            //console.error("Error fetching leaves records:", error);
            toast.error("Failed to fetch leaves records.", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    // Function to handle radio button changes
    const handleRadioChange = (rowIndex, leave_type, reason, comment) => {
        // console.log('rowIndex', rowIndex, 'leave_type', leave_type, 'reason', reason)
        const updatedMainData = mainData.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, leaveType: leave_type, reason: reason, comment: comment };
            }
            return row;
        });
        setMainData(updatedMainData);
    }

    // click Apply button and update and insert data
    const handleDataSave = async () => {
        const hasEmptySelectedOption = mainData.some(item => !item.leaveType && !item.isHoliday && !item.isWeekend);
        if (hasEmptySelectedOption) {
            toast.error("Select a day for all dates.");
            return;
        }

        const hasEmptyReason = mainData.some(item => !item.reason && !item.isHoliday && !item.isWeekend);
        if (hasEmptyReason) {
            toast.error("Reason field is mandatory before applying.");
            return;
        }

        setIsSpinner(true);
        // console.log('main Data: Apply Click ->', mainData);
        let allLeaveRecords = { "base64File": base64String, "contentType": fileDetails.type, "notifyMembers": extractedEmails, "leaveDetails": mainData };
        //console.log('allLeaveRecords', allLeaveRecords)
        const responce = await HRMSApi.createLeavesRecords(allLeaveRecords); // Leaves Records
        // console.log('Create All Leaves Records', responce);
        if (responce.success) {
            fetchRecords();
            const successMessage = 'Leaves saved successfully.';
            const isSuccessMessage = responce.records.toLowerCase().includes(successMessage.toLowerCase());
            isSuccessMessage ? toast.success(responce.records, { position: toast.POSITION.TOP_RIGHT }) : toast.error(responce.records, { position: toast.POSITION.TOP_RIGHT });
            setRowData({});
            setMainData([]);
            setBase64String('');
            setFileShowHide(false);
            setFileDetails({ name: '', type: '' });
            setselectedusers([])
        } else {
            setIsSpinner(false);

        }
    }

    const labels = { beforeSelect: " " }

    //table headers
    const header = [
        { title: 'Date', prop: 'leaveDate', isFilterable: true, isSortable: true },
        { title: 'Reason', prop: 'reason', isFilterable: true, isSortable: true },
        { title: 'Leave Type', prop: 'leaveType', isFilterable: true, isSortable: true },
        {
            title: 'Status',
            prop: 'status',
            isFilterable: true,
            isSortable: true,
            cell: (row) => {
                return (
                    <>
                        {row.status === 'Approved' ?
                            (
                                <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(154 235 233)', cursor: "default" }} >
                                    {row.status}
                                </Button>
                            ) :
                            row.status === 'Pending for Approval' ?
                                (
                                    <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(241 218 177)', cursor: "default" }} >
                                        {row.status}
                                    </Button>
                                ) :
                                row.status === 'Rejected' ? (
                                    <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(248 195 200)', cursor: "default" }} >
                                        {row.status}
                                    </Button>
                                ) :
                                    row.status === 'Adjusted' ? (
                                        <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(208 187 244)', cursor: "default" }} >
                                            {row.status}
                                        </Button>
                                    ) :
                                        row.status === 'Revoked' ? (
                                            <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(199, 216, 241)', cursor: "default" }} >
                                                {row.status}
                                            </Button>
                                        ) :
                                            null
                        }
                    </>
                );

            },
        },
        {
            title: 'Action',
            prop: 'leaveId',
            cell: (row) => {
                const currentDate = moment().startOf('day').toDate();
                return (
                    <>
                        {row.status !== 'Revoked' && (row.status === 'Approved' || row.status === 'Pending for Approval' || row.status === 'Adjusted') &&
                            (moment(row.leaveDate, 'DD-MM-YYYY').toDate() >= currentDate) && (
                                <Button className="btn-sm" variant='' style={{ borderRadius: "15px", backgroundColor: 'rgb(199, 216, 241)' }}
                                    onClick={() => handleClickRevoked(row)}
                                >
                                    Revoke
                                </Button>
                            )
                        }
                    </>
                );
            },
        },
    ];

    // handle custom revoke button click
    const handleClickRevoked = (row) => {
        setRevokeModalShow(true);
        setRevokedLeaveRowData(row)
    };

    // update leave status Revoked
    const handleChangeRevoked = async () => {
        if (revokedLeaveRowData) {
            let obj = {
                leaveId: revokedLeaveRowData.leaveId,
                leaveDate: moment(revokedLeaveRowData.leaveDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                status: revokedLeaveRowData.status,
                reason: revokedLeaveRowData.reason,
                leaveType: revokedLeaveRowData.leaveType
            }
            const update_leave = await HRMSApi.updateLeaveRecord(obj); // Leaves Table Data
            if (update_leave.success) {
                setRevokeModalShow(false);
                setIsSpinner(true);
                fetchRecords();
                setRowData({});
                setMainData([]);
                setTimeout(() => {
                    setIsSpinner(false);
                    toast.success(update_leave.record, { position: toast.POSITION.TOP_RIGHT });
                }, 500);
            } else {
                setIsSpinner(false);
                setRevokeModalShow(true);
            }
        }
    }

    // month, year by search records
    const onFilterByMonth = async (event) => {
        setSelectMonthYear({ ...selectMonthYear, [event.target.name]: event.target.value });
        const { name, value } = event.target;
        if (name === 'month' && value === '') {
            setSelectMonthYear({ month: value, year: '' });// If month changes, reset the year selection
        } else if (name === 'year' && value === '') {
            setSelectMonthYear({ month: '', year: value });// If year changes, reset the month selection
        }
    }
    //full day and half day checkBox click then all checkBox true
    const handleCheckboxChange = (e) => {
        e.stopPropagation();
        const { name, checked } = e.target;
        const updatedMainData = mainData.map(row => {
            if ((name === 'full_day' || name === 'half_day') && !row.isWeekend && !row.isHoliday) {
                return { ...row, leaveType: checked ? (name === 'full_day' ? 'Full Day' : 'Half Day') : '' };
            }
            return row;
        });
        setMainData(updatedMainData);
    };

    // upload file
    const fileHandleChange = async (event) => {
        const file = event.target.files[0];
        const allowedFormats = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
        const maxSize = 3 * 1024 * 1024; // 3 MB in bytes

        //Check if a file is selected
        if (file) {
            if (!allowedFormats.includes(file.type)) {
                formRef.current.reset();
                setFileDetails({ name: '', type: '' });
                setFileErrorLimit('');
                setFileError('Only PDF, JPG, JPEG, and PNG formats are allowed');
                // event.target.value = null;
            }
            else if (file.size > maxSize) {
                formRef.current.reset();
                setFileDetails({ name: '', type: '' });
                setFileError('');
                setFileErrorLimit('File size exceeds the maximum limit of 3 MB');
            }
            else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const fileContent = reader.result.split(",")[1];
                    setBase64String(fileContent);
                };
                reader.readAsDataURL(file);

                setFileDetails({ name: file.name, type: file.type.split("/")[1] });
                setFileError('');
                setFileErrorLimit('');
            }
        }
        else { // If no file is selected, clear the error message and file name
            setFileError('');
            setFileDetails({ name: '', type: '' });
        }
    }

    // File uplaod
    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    // Leave Notify team member work 

    useEffect(() => {

        fetchAllUsers()
    }, []);
    const fetchAllUsers = async () => {
        //console.log('call fetch user')
        let result = await HRMSApi.getAllUsers();
        //console.log('result users', result)
        if (result.success) {
            const updatedUsersList = result.record.map((value) => ({
                label: value.empName,
                email: value.empEmail,
                profileURL: value.profileURL
            }));

            setUsersList([...usersList, ...updatedUsersList]);
            //console.log('usersList', updatedUsersList)
        }

    }
    const removeBadge = (indexToRemove) => {
        setselectedusers(selectedusers.filter((_, index) => index !== indexToRemove));
    };

    const [selectedUserEmails, setSelectedUserEmails] = useState('');

    const handleUserSelection = (selected) => {
        //console.log('selected===>', selected);
        if (selected.length <= 10) {
            const selectedLabel = selected.map(item => ({ label: item.value, value: item.value }));
            settempselectionuser(selectedLabel)
            const selectedLabels = selected.map(item => ({ label: item.value.split('-')[0].trim(), value: item.value }));
            setselectedusers(selectedLabels);

        } else {
            const selectedLabels = selected.slice(0, 10).map(item => ({ label: item.value, value: item.value }));
            setselectedusers(selectedLabels);
            toast.error("Maximum 10 Members can be selected", { position: toast.POSITION.TOP_RIGHT });


        }
    };

    useEffect(() => {
        const extractedEmails = tempselectionuser.map(item => {
            const splitLabel = item.label.split('-');
            return splitLabel[1];
        });

        //console.log('Extracted Emails:', extractedEmails);
        setextractedEmails(extractedEmails)
    }, [tempselectionuser]);

    function customFilter(options, filter) {
        const filteredOptions = options.filter(option => {
            const labelParts = option.value.split(',');
            //console.log('labelParts ===>',labelParts)
            const label = labelParts[labelParts.length - 1];
            //console.log('label ===>',label)
            let labelsplit = label.split('-')[0];
            //console.log('label split',labelsplit)
            return labelsplit.toLowerCase().includes(filter.toLowerCase());
        });
        return filteredOptions;
    }

    return (
        <>
            {/*  My Leaves Section */}
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10} xs={12} sm={12}>
                        <div className="p-2 text-center" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px", }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>Leaves</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {!isSpinner ? (<>
                {/* Box Leaves Section */}
                <Container>
                    <Row>
                        <Col></Col>
                        {accountName === constants?.Company_Name ?
                            <Col lg={10}>
                                <OwlCarousel className='owl-theme' loop margin={10} autoplay autoplayTimeout={3000} nav={false} items={4} responsive={{
                                    0: { items: 1 },// 1 item for mobile view
                                    600: { items: 2 },// 2 items for tablets
                                    1000: { items: 4 }// 4 items for larger screens
                                }} >
                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(154 235 233)", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(154 235 233)" }} ></i>
                                                <i className="fa-thin fa-check fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>
                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.approvedLeaves}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#C9F6F5" }} >Approved</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid #e0c5f6", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#e0c5f6" }} ></i>
                                                <i className="fa-thin fa-check fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>
                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.adjustedLeaves}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#C9F6F5" }} >Adjusted</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(207 203 214)", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(207 203 214)" }} ></i>
                                                <i className="fa-solid fa-hourglass-end fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>

                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.usedCL}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Used CL</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(249 209 209)", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(249 209 209)" }} ></i>
                                                <i className="fa-solid fa-thin fa-hourglass fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>

                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.usedOptional}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Used Optional</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(241 218 177)", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(241 218 177)" }} ></i>
                                                <i className="fa-sharp fa-solid fa-hourglass-half fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>

                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.pendingCL}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Pending CL</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(199 216 241)", borderRadius: "5px", }} >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(199 216 241)" }} ></i>
                                                <i className="fa-solid fa-money-bill-1 fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                            </span>

                                            <div className="flex-grow-1">
                                                <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.lossOfPay}</h4>
                                                <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Loss of Pay</h6>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </Col>
                            :
                            <Col lg={10}>
                                <Row>
                                    <Col lg={4}>
                                        <div className='item'>
                                            <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(241 218 177)", borderRadius: "5px", }} >
                                                <span className="fa-stack fa-2x">
                                                    <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(241 218 177)" }} ></i>
                                                    <i className="fa-sharp fa-solid fa-hourglass-half fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                                </span>

                                                <div className="flex-grow-1">
                                                    <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.pendingCL}</h4>
                                                    <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Pending CL</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className='item'>
                                            <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(154 235 233)", borderRadius: "5px", }} >
                                                <span className="fa-stack fa-2x">
                                                    <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(154 235 233)" }} ></i>
                                                    <i className="fa-thin fa-check fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                                </span>
                                                <div className="flex-grow-1">
                                                    <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.approvedLeaves}</h4>
                                                    <h6 className="text-muted mb-1" style={{ color: "#C9F6F5" }} >Approved</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className='item'>
                                            <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(199 216 241)", borderRadius: "5px", }} >
                                                <span className="fa-stack fa-2x">
                                                    <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(199 216 241)" }} ></i>
                                                    <i className="fa-solid fa-money-bill-1 fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                                </span>

                                                <div className="flex-grow-1">
                                                    <h4 className="mb-0 d-inline mx-3">{leaveDashboardData?.lossOfPay}</h4>
                                                    <h6 className="text-muted mb-1" style={{ color: "#605C68" }} >Loss of Pay</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        <Col></Col>
                    </Row>
                </Container>


                {/* Leave Form Section */}
                <Container className='mt-3 mb-2'>
                    <Row>
                        <Col></Col>
                        <Col lg={4} sm={12} xs={12} className="mb-3">
                            <Card className='h-100 mt-3' style={{ height: "27rem", borderTop: "3px solid #4f6479", }}>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                    Start Date
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={rowData?.start_date || ''}
                                                    type="date"
                                                    name="start_date"
                                                    onChange={handleChange}
                                                    min={dateFormate(firstDayOfCurrentMonth)}
                                                    max={dateFormate(lastDayOfNextMonth)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                    End Date
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={rowData.end_date || ''}
                                                    type="date"
                                                    name="end_date"
                                                    onChange={handleChange}
                                                    min={dateFormate(firstDayOfCurrentMonth)}
                                                    max={dateFormate(lastDayOfNextMonth)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                    Reason
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    placeholder="Select Reason"
                                                    aria-label="Default select example"
                                                    name="reason"
                                                    value={rowData.reason}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">-- None --</option>
                                                    <option value="Death">Death</option>
                                                    <option value="Exam">Exam</option>
                                                    <option value="Festival">Festival</option>
                                                    <option value="Function">Function</option>
                                                    <option value="Marriage">Marriage</option>
                                                    <option value="Maternity">Maternity</option>
                                                    <option value="Medical">Medical</option>
                                                    <option value="Out of Station">Out of Station</option>
                                                    <option value="Paternity">Paternity</option>
                                                    <option value="Vacation">Vacation</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group>
                                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                    Comment
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="comment"
                                                    value={rowData.comment}
                                                    placeholder='Enter comment...'
                                                    onChange={handleChange}
                                                    rows={2}
                                                    maxLength={255}
                                                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', resize: 'none' }}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12} sm={12} xs={12} className='my-3'>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                Notify Team Members
                                            </Form.Label>

                                            <MultiSelect
                                                value={selectedusers}
                                                options={usersList.map((item, index) => ({
                                                    label:
                                                        (
                                                            <div key={item.email}>
                                                                <img alt="User" className="member-pic mx-3" src={item.profileURL ? item.profileURL : "default_emp_profile.png"} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                                                <span>{item.label}</span>
                                                            </div>
                                                        ),
                                                    value: item.label + '-' + item.email,
                                                }))}

                                                onChange={(selected) => handleUserSelection(selected)}
                                                labelledBy="documentType-label"
                                                hasSelectAll={false}
                                                filterOptions={customFilter}
                                            // disableSearch = {true}

                                            />


                                        </Col>
                                        <>
                                            {selectedusers.map((value, index) => (
                                                <Card key={index} className="d-inline-flex justify-content-start mx-1 my-1" style={{ width: `${value.label.length * 10}px`, height: '25px', backgroundColor: '#e8e9ed' }}>
                                                    <Card.Body className="d-flex align-items-center" style={{ fontSize: '11px', fontWeight: 'lighter', padding: '0px', position: 'relative' }}>
                                                        {value.label}
                                                        <Button variant="transparent" size='sm' className="btn-close" onClick={() => removeBadge(index)} style={{ color: '#A2A2A2', padding: '2px', position: 'absolute', right: '0', width: '7px', height: '6px' }}>
                                                            <i className="bi bi-x"></i>
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            ))}

                                        </>
                                        {fileShowHide && (
                                            <Col lg={12} sm={12} xs={12} className='text-center'>
                                                <Form ref={formRef} className=' mb-0 mt-3'>
                                                    <Form.Group controlId="formFile">
                                                        <Form.Label className="mt-2">
                                                            <div className='p-2' style={{ cursor: "pointer", border: "1px dotted", color: "rgb(201, 188, 188)" }} title='Upload file'>
                                                                <i className="fa-solid fa-cloud-arrow-up mx-5"
                                                                    style={{ cursor: "pointer", fontSize: "30px", color: "rgb(144 231 245)" }}
                                                                    onChange={handleFileInputClick}
                                                                ></i><br />
                                                                {fileDetails?.name && <span>{fileDetails?.name}</span>}
                                                            </div>
                                                        </Form.Label>
                                                        <Form.Control
                                                            hidden
                                                            type="file"
                                                            name="file"
                                                            accept=".pdf, .jpg, .jpeg, .png" // Specify accepted file formats
                                                            onChange={fileHandleChange}
                                                            ref={fileInputRef}
                                                        /><br />
                                                        <Form.Text className={fileError ? 'text-danger' : 'text-black'}>Only <span style={{ fontSize: "10px", fontWeight: "600" }}>PDF, JPG, JPEG, and PNG</span> formats are allowed.</Form.Text><br />
                                                        <Form.Text className={fileErrorLimit ? 'text-danger' : 'text-black'}>Max File size: <span style={{ fontSize: "10px", fontWeight: "600" }}>3 MB</span></Form.Text>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        )}

                                    </Row>
                                </Card.Body>

                                <Card.Footer style={{ backgroundColor: "white" }} >
                                    <Row className='mt-2'>
                                        <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                            <button className='btn btn-outline-primary float-end mb-2' onClick={handleNext}>
                                                Next
                                            </button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>

                        <Col lg={6} sm={12} xs={12} className="mb-3">
                            {mainData.length > 0 ? (
                                <Card className='h-100 mt-3' style={{ height: "27rem", borderTop: "3px solid #4f6479", }}>
                                    <Card.Body style={{ height: "21rem" }}>
                                        <Row>
                                            <Col lg={12} sm={12} xs={12}>
                                                <div className="table-container" style={{ maxHeight: "20rem", overflowY: "auto" }}>
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>
                                                                    Full Day
                                                                    <input
                                                                        className='mx-1'
                                                                        type="checkbox"
                                                                        name='full_day'
                                                                        checked={mainData.filter(row => !row.isWeekend && !row.isHoliday).every(row => row.leaveType === "Full Day")}
                                                                        disabled={mainData.every(row => row.isWeekend || row.isHoliday)} // Disable if all rows are weekends or holidays
                                                                        onChange={handleCheckboxChange} />
                                                                </th>
                                                                <th>
                                                                    Half Day
                                                                    <input
                                                                        className='mx-1'
                                                                        name='half_day'
                                                                        type="checkbox"
                                                                        checked={mainData.filter(row => !row.isWeekend && !row.isHoliday).every(row => row.leaveType === "Half Day")}
                                                                        disabled={mainData.every(row => row.isWeekend || row.isHoliday)} // Disable if all rows are weekends or holidays
                                                                        onChange={handleCheckboxChange} />
                                                                </th>
                                                                {accountName === constants?.Company_Name &&
                                                                    <th>Optional</th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mainData && mainData.map((row, rowIndex) => (//rgb(248 249 199 / 68%)
                                                                <tr key={rowIndex} >
                                                                    <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? (accountName === constants?.Company_Name ? 'rgb(248 249 199 / 68%)' : 'transparent') : "transparent" }}>
                                                                        {moment(row?.leaveDate).format('DD-MM-YYYY')}
                                                                    </td>
                                                                    <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? (accountName === constants?.Company_Name ? 'rgb(248 249 199 / 68%)' : 'transparent') : "transparent" }}>
                                                                        <input
                                                                            className='mx-1'
                                                                            type="radio"
                                                                            name={`radio-${rowIndex}`}
                                                                            value="Full Day"
                                                                            checked={row.leaveType === "Full Day"}
                                                                            disabled={row.isWeekend || row.isHoliday}
                                                                            onChange={() => handleRadioChange(rowIndex, "Full Day", rowData.reason, rowData.comment)}
                                                                        // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                                        />
                                                                    </td>
                                                                    <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? (accountName === constants?.Company_Name ? 'rgb(248 249 199 / 68%)' : 'transparent') : "transparent" }}>
                                                                        <input
                                                                            className='mx-1'
                                                                            type="radio"
                                                                            name={`radio-${rowIndex}`}
                                                                            value="Half Day"
                                                                            checked={row.leaveType === "Half Day"}
                                                                            disabled={row.isWeekend || row.isHoliday}
                                                                            onChange={() => handleRadioChange(rowIndex, "Half Day", rowData.reason, rowData.comment)}
                                                                        // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                                        />
                                                                    </td>
                                                                    {accountName === constants?.Company_Name &&
                                                                        <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? 'rgb(248 249 199 / 68%)' : "transparent" }}>
                                                                            <input
                                                                                className='mx-1'
                                                                                type="radio"
                                                                                name={`radio-${rowIndex}`}
                                                                                value="Optional"
                                                                                checked={row.leaveType === "Optional"}
                                                                                disabled={row.isWeekend || row.isHoliday || !row.isOptional}
                                                                                onChange={() => handleRadioChange(rowIndex, "Optional", rowData.reason, rowData.comment)}
                                                                            // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                                            />
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>

                                    <Card.Footer style={{ backgroundColor: "white" }} >
                                        <Row className='mt-2'>
                                            <Col lg={10} sm={10} xs={10} className="mt-2 justify-content-start">
                                                <span className='mx-1'>
                                                    <svg width="20" height="10">
                                                        <rect width="20" height="10" style={{ fill: "#FEE2E5" }} />
                                                    </svg>
                                                    <span> Weekend</span>
                                                </span>
                                                <span className='mx-1'>
                                                    <svg width="20" height="10">
                                                        <rect width="20" height="10" style={{ fill: "#E1F0FE" }} />
                                                    </svg>
                                                    <span> Holiday</span>
                                                </span>
                                                {accountName === constants?.Company_Name &&
                                                    <span className='mx-1'>
                                                        <svg width="20" height="10">
                                                            <rect width="20" height="10" style={{ fill: "rgb(248 249 199 / 68%)" }} />
                                                        </svg>
                                                        <span>Optional</span>
                                                    </span>
                                                }
                                            </Col>

                                            <Col lg={2} sm={2} xs={2} className="justify-content-end">
                                                <button className='btn btn-outline-primary float-end mb-2' onClick={handleDataSave} disabled={mainData.every(row => row.isWeekend || row.isHoliday)} >
                                                    Apply
                                                </button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>

                                </Card>
                            ) :
                                isMainData ?
                                    (
                                        <>
                                            <ShimmerTable row={1} col={3} />
                                            <p className='text-center'>Click "Next" to Proceed</p>
                                            <div className="custom-shimmer-class mt-4">
                                                <ShimmerTable row={1} col={3} />
                                            </div>
                                        </>
                                    )
                                    :
                                    ''
                            }
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>

                {/* Message */}
                {accountName === constants?.Company_Name &&
                    mainData.length > 0 && (
                        <Container className='mt-1'>
                            <Row>
                                <Col></Col>
                                <Col lg={4} sm={12} xs={12} ></Col>
                                <Col lg={6} sm={12} xs={12}>
                                    <i>Note: Optional feature is only available for Optional Holidays.</i>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    )
                }


                {/* Table Leaves Section */}
                <Container className='mt-4 mb-5' style={{ paddingBottom: "60px" }}>
                    <Row>
                        <Col></Col>
                        <Col lg={10} sm={12} xs={12}>
                            {body ?
                                <DatatableWrapper
                                    body={body}
                                    headers={header}
                                    paginationOptionsProps={{ initialState: { rowsPerPage: 10, options: [5, 10, 15, 20] } }}
                                    sortProps={{
                                        sortValueObj: {
                                            leaveDate: (leaveDate) => {
                                                const [day, month, year] = leaveDate.split('-').map(Number);
                                                return -new Date(year, month - 1, day).getTime();
                                            }
                                        }
                                    }}
                                >
                                    <Row className="mb-2 justify-content-end">
                                        <Col lg={4} xs={12} sm={12}>
                                            <Row className='justify-content-end'>
                                                <Col lg={4} xs={12} sm={12} style={{ width: "fit-content" }}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Select
                                                            aria-label="Enter status"
                                                            name="month"
                                                            onChange={onFilterByMonth}
                                                            value={selectMonthYear?.month || ''}
                                                        >
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3} xs={12} sm={12} style={{ width: "fit-content" }}>
                                                    <Form.Group className='mb-3' >
                                                        <Form.Select
                                                            aria-label="Enter year"
                                                            name="year"
                                                            onChange={onFilterByMonth}
                                                            value={selectMonthYear?.year || ''}
                                                        >
                                                            <option value="">Year</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2023">2023</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Col>

                                        <Col lg={3} xs={12} sm={12} className='mb-3'>
                                            <Filter placeholder='Search...' />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} sm={12} xs={12} className='table-min-div'>
                                            <Table striped className="data-table">
                                                <TableHeader />
                                                <TableBody />
                                            </Table>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-center justify-content-end ">
                                        <Col lg={12} xs={12} sm={12} className="d-flex align-items-center justify-content-end">
                                            <span className='me-2 mt-2'>
                                                Show
                                            </span>
                                            <PaginationOptions labels={labels} />
                                            <div className='mt-2 mx-2'>
                                                Records
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4 align-items-center justify-content-end ">
                                        <Col lg={12} xs={12} sm={12}>
                                            <div className='text-end mt-3'>
                                                <Pagination />
                                            </div>
                                        </Col>
                                    </Row>
                                </DatatableWrapper>
                                : <ShimmerTable row={3} col={5} />
                            }
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </>
            ) :
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            }

            {revokeModalShow && (
                <LeaveStatusUpdate
                    show={revokeModalShow}
                    onHide={() => setRevokeModalShow(false)}
                    revoked={handleChangeRevoked}
                    title="Confirm Revoke"
                    table="leaves"
                />
            )}

            <ToastContainer />
            <Footer />
        </>
    )
}

export default Leave