import React, { Fragment, useEffect, useState } from 'react'
import { Accordion, Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import HRMSApi from '../../api/HRMSApi';
import jwtDecode from 'jwt-decode';
import MedicalCardShimmer from './MedicalCardShimmer';
const MedicalCard = () => {
    const [medicalFormData, setMedicalFormData] = useState();
    useEffect(() => {
        (async () => {
            let token = jwtDecode(localStorage.getItem('token'))
            let result = await HRMSApi.getEmployeeMedicalForm(token.contactDetails?.Id);
            if (result.success) {
                setMedicalFormData(result.records);
            } else {
                setMedicalFormData([]);
            }
        })();
    }, []);

    const handleDownloadPdf = (downloadContent) => {
        const newWindow = window.open(downloadContent, '_blank');
        if (newWindow) {
            newWindow.addEventListener('load', () => {
                const downloadLink = newWindow.document.createElement('a');
                downloadLink.href = downloadContent;
                let attachementname = 'medicalreport.pdf'
                downloadLink.download = attachementname;
                downloadLink.click();
            })
        }
    }
    return (
        <Fragment>
            <Container className='mt-5'>
                <Row className='justify-content-center'>
                    <Col lg={10} xs={12} sm={12}>
                        <div
                            className=' text-center p-2'
                            style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}
                        >
                            <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'large' }}>
                                View Medical Card
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                medicalFormData != undefined ?
                    (medicalFormData.length > 0) ?
                        medicalFormData?.map((item, index) => (
                            <Container className='mt-2 mb-2'>
                                <Row className='d-flex justify-content-center '>
                                    <Col lg={10} sm={12} xs={12}>
                                        <Card.Body>
                                            <ListGroup variant='flush'>
                                                <ListGroup.Item>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', textAlign: 'center' }}>
                                                            {item.finalcialyear}
                                                        </span>
                                                        <i class="fa-solid fa-download fs-5" title='Download' style={{ cursor: "pointer", color: "rgb(3, 169, 244)" }} onClick={() => handleDownloadPdf(item.medicalCard)}></i>
                                                    </div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Container>
                        ))
                        :
                        <Container className='mt-2'>
                            <Row className='d-flex justify-content-center '>
                                <Col lg={10} sm={12} xs={12}>
                                    <Card.Body>
                                        <ListGroup variant='flush'>
                                            <ListGroup.Item>
                                                <div className='d-flex justify-content-between'>
                                                    <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', textAlign: 'center' }}>
                                                        Medical Card is Not Available
                                                    </span>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Container>
                    : <MedicalCardShimmer />
            }
        </Fragment>
    )
}

export default MedicalCard