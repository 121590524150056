import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import HRMSApi from "../../api/HRMSApi";
import { ShimmerPostItem } from 'react-shimmer-effects';
import UpdateImage from '../modal/UpdateImage';
import * as constants from "../../constants/CONSTANT";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState({});
  const [contactRecords, setContactRecords] = useState();
  const [employeeImage, setEmpyeeImage] = useState();
  const [imageModalShowHide, setImageModalShowHide] = useState();
  const [accountName, setAccountName] = useState();
  const token = localStorage.getItem("token");
  let decodedToken = jwt_decode(token);

  const navigatePage = (destination, defaultHide) => {
    if (isMobile || defaultHide)
      hideSideBar();
    setTimeout(() => { navigate(destination); }, 100)
  }
  const hideSideBar = () => {
    document.querySelector("#sidebar").classList.toggle("show");
    document.querySelector("#sidebar").classList.toggle("hide");
  }

  useEffect(() => {
    setAccountName(decodedToken.contactDetails.Name)
    fetchContactRecs();
    fetchEmployeeImage();
  }, []);

  //fetch contact record
  const fetchContactRecs = async () => {
    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);
    const contactDetailsString = decodedToken.contactDetails;
    const result = await HRMSApi.getContactRecordByEmail(contactDetailsString?.Email);
    setContactRecords(result.records);
  }

  //Employee Image
  const fetchEmployeeImage = async () => {
    const imageurl = await HRMSApi.getImage();
    if (imageurl.success) {
      setEmpyeeImage(imageurl.record);
    }
  }
  // image icon click
  const handleClickEditImage = (val) => {
    if (val) {
      setImageModalShowHide(val);
    } else {
      setImageModalShowHide({ url: 'default_emp_profile.png', name: '' });
    }
  }

  const imageRefresh = () => {
    fetchEmployeeImage();
    setImageModalShowHide();
  }

  useEffect(() => {
    if (location?.state?.empData === 'refresh_EmployeeName') {
      fetchContactRecs();
      // console.log('refresh_EmployeeName', location?.state?.empData)
    }
  }, [location.state]);

  return (
    <>
      <nav id="sidebar" className={isMobile ? 'hide' : 'show'} style={{ fontSize: "small" }}>
        <div>
          <div className="sidebar-header">
            {contactRecords ? (
              <div className='container-fluid' >
                <div className="row">
                  {/* Employee Image || START */}
                  <div className="col-lg-12 col-md-4 col-sm-6">
                    <div className='img-className' >
                      <i className="fa-solid fa-pencil"
                        title='Upload Image'
                        onClick={() => { handleClickEditImage(employeeImage) }}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(255 255 255)",
                          padding: "7px",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "#000",
                          position: "absolute",
                          right: "18PX",
                          top: "9PX",
                        }}></i>
                      <img alt=""
                        className="member-pic hoverBox"
                        src={employeeImage && employeeImage.url != null ? employeeImage.url : 'default_emp_profile.png'}
                      />
                    </div>
                  </div>
                  {/* Employee Image || END */}
                  {/* Employee Details || START */}
                  <div className="col-lg-12 col-md-4 col-sm-6">
                    <div className='content-text' style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <div className='row text-start mx-0'>
                        <div className='col-lg-5 col-sm-4'>
                          <span className='member-name'>Name: </span>
                        </div>
                        <div className='col-lg-7 col-sm-8'>
                          <span className='member-name'> {contactRecords.Name} </span> <br className="sm-break" />
                        </div>
                        <div className='col-lg-5 col-sm-4'>
                          <span className='member-name'>Designation:</span>
                        </div>
                        <div className='col-lg-7 col-sm-8'>
                          <span className='member-name'> {contactRecords.Designation__c}</span> <br className="sm-break" />
                        </div>
                        <div className='col-lg-5 col-sm-4'>
                          <span className='member-name'>Experience:</span>
                        </div>
                        <div className='col-lg-7 col-sm-8'>
                          <span className='member-name'>{contactRecords.Current_Experience__c}</span>
                          <br className="sm-break" />
                        </div>
                        <div className='col-lg-5 col-sm-4'>
                          <span className='member-name'>Branch: </span>
                        </div>
                        <div className='col-lg-7 col-sm-8'>
                          <span className='member-name'>{contactRecords.Branch__c}</span>  <br className="sm-break" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Employee Details || END */}
                </div>
              </div>
            ) :
              <ShimmerPostItem card title cta imageType="thumbnail" imageWidth={80} imageHeight={20} contentCenter />
            }
          </div>

          {/* Employee Sidebar header name || START */}
          <ul className="list-unstyled components scrollbar-thin" >
            <li className={`${location.pathname === '/' ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/", false)} ><i className="fa-solid fa-house mx-3 side-option-icon" ></i><span className="menu-item-txt">Home</span></span>
            </li>

            <li className={`${location.pathname.includes('/payslips') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/payslips", false)}><i className="fa-solid fa-building mx-3 side-option-icon"></i><span className="menu-item-txt" style={{ paddingLeft: "3px" }}>Pay Slips</span></span>
            </li>

            {accountName === constants.Company_Name &&
              <li className={`${location.pathname.includes('/timesheet') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/timesheet", false)}><i className="fa-regular fa-clock mx-3 side-option-icon"></i><span className="menu-item-txt">Time Sheet</span></span>
              </li>
            }

            <li className={`${location.pathname.includes('/leaves') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/leaves", false)}><i className="fa-solid fa-person-walking-luggage mx-3"></i><span className="menu-item-txt">Leaves</span></span>
            </li>

            <li className={`${location.pathname.includes('/taxdeductedsource') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/taxdeductedsource", false)}><i className="fa-solid fa-calculator mx-3"></i><span className="menu-item-txt">Tax Report</span></span>
            </li>

            <li className={`${location.pathname.includes('/taxformsixteen') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/taxformsixteen", false)}><i className="fa-regular fa-money-bill-1 mx-3"></i><span className="menu-item-txt">Form-16</span></span>
            </li>

            {accountName === constants.Company_Name &&
              <li className={`${location.pathname.includes('/medicalcard') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/medicalcard", false)}><i class="fa-solid fa-suitcase-medical mx-3"></i><span className="menu-item-txt">Medical Card</span></span>
              </li>
            }
            <li className={`${location.pathname.includes('/tax12BBForm') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/tax12BBForm", false)}><i className="fa-solid fa-calculator mx-3"></i><span className="menu-item-txt">Add 12BB Form</span></span>
            </li>


            {accountName === constants.Company_Name &&
              <li className={`${location.pathname.includes('/handbook') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/handbook", false)}><i className="fa-solid fa-book mx-3"></i><span className="menu-item-txt">Handbook</span></span>
              </li>
            }
            {contactRecords?.Designation__c === "Administrator" &&
              accountName === constants.College_Name &&
              <li className={`${location.pathname.includes('/leaveapproval') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/leaveapproval", false)}><i className="fa fa-home mx-3" aria-hidden="true"></i><span className="menu-item-txt">Leave Approval</span></span>
              </li>
            }

            <li className={`${location.pathname.includes('/feedback') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/feedback", false)}><i className="fa-solid fa-comment-dots mx-3"></i><span className="menu-item-txt">Feedback</span></span>
            </li>

            <li className={`${location.pathname.includes('/changepassword') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/changepassword", false)}><i className="fa fa-key mx-3 side-option-icon"></i><span className="menu-item-txt">Change Password</span></span>
            </li>

            <div className="sidebar-header" style={{ borderTop: "1px solid #fff", textAlign: "center" }}>
              {
                accountName === constants.Company_Name ?
                  <img alt="" src="/ibirds-logo.png" style={{ width: "50%" }} />
                  : <img alt="" src="/college_logo.png" style={{ width: "50%" }} />
              }
              <p style={{ paddingTop: ".5rem", fontSize: "12px", fontWeight: "600" }}>Powered by <a href="https://indicrm.io" style={{ color: "#fff", fontSize: "12px", fontWeight: "600" }}>iBirds</a></p>
            </div>

          </ul>
          {/* Employee Sidebar header name || END */}
        </div>
      </nav>

      {/* Image modal */}
      {imageModalShowHide && (
        <UpdateImage
          show={imageModalShowHide}
          onHide={() => setImageModalShowHide(false)}
          imageRefresh={imageRefresh}
        />
      )}
    </>
  )
}

export default Sidebar