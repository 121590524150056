import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Accordion, ListGroup, ListGroupItem, Table } from 'react-bootstrap'
import HRMSApi from '../../api/HRMSApi';
import { ToastContainer, toast } from 'react-toastify';
import LeaveShimmer from './LeaveShimmer';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import * as containts from '../../constants/CONSTANT';
import { useNavigate } from 'react-router-dom';
const LeaveApproval = () => {
    // State to store the API result as object from the backend.
    let [employeeLeavesApprovals, setEmployeeLeavesApprovals] = useState(undefined);
    let [shimmerShow, setShimmerShow] = useState(false);
    let navigate = useNavigate();

    // Retiving the leaves of employee from database
    const getEmployeeLeaveRequest = async (userEmail) => {
        try {
            let result = await HRMSApi.getEmployeeLeavesRequest();            
            setEmployeeLeavesApprovals(result.data.records);           
            // Find current user 
            let userDetails = (await HRMSApi.getContactRecordByEmail(userEmail));
            // For those user who is from college account but not has rights for administrator then it will redirect to the home page if they want to access the page forcefully.
            userDetails.records.Designation__c != 'Administrator' && navigate('/');
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('token');
        // For those who has company account but want to access this section then they can't be able to access that.
        if (jwtDecode(token).contactDetails.Name === containts.Company_Name) navigate("/");
        getEmployeeLeaveRequest(jwtDecode(token).contactDetails.Email);
        setTimeout(() => {
            setShimmerShow(false);
        }, 1000)
    }, [shimmerShow])


    // Check if all the approved and rejected feild is selected or not. 
    const checkIsAllUnSelected = () => {
        let helperArr = [];
        let helperRecordSaver = [];
        let helperValue = 0;
        employeeLeavesApprovals.map((record) => {
            helperValue += record.leaveRecords.length;
            for (let i of record.leaveRecords) {
                if (i.approved === 'Pending for Approval') helperArr.push(i.approved);
                else helperRecordSaver.push({ type: i.type, reason: i.reason, leaveId: i.leaveId, fDate: i.fDate, approved: i.approved ? "Approved" : "Rejected" });
            }
        })
        if (helperArr.length === helperValue) {
            toast.error('Please select at least one record of leave.', { position: toast.POSITION.TOP_RIGHT });
            return false;
        }
        else {
            return helperRecordSaver;
        }
    }


    // Trigger when the user click on submit button
    const handleSubmit = async () => {
        let recordReciver = checkIsAllUnSelected();
        if (recordReciver.length > 0) {
            recordReciver = recordReciver.map(({ leaveId, approved }) => { return { leaveId, approved } })
            let successResult = await HRMSApi.approveOrRejectLeaveByAdmin(recordReciver);
            if (successResult.success) {
                setShimmerShow(true);
                toast.success(successResult.message, { position: toast.POSITION.TOP_RIGHT })
            }
        }
    }


    // function to approve the leaves status when click on approved buttton
    const approveLeave = (approvId, approvName) => {
        employeeLeavesApprovals = (employeeLeavesApprovals.map(item => {
            if (item.employeName === approvName) {
                return ({
                    leaveRecords: item.leaveRecords.map(record => {
                        if (record.leaveId === approvId) return { type: record.type, reason: record.reason, leaveId: record.leaveId, fDate: record.fDate, approved: true }
                        else return record;
                    }), employeName: approvName
                })
            } else {
                return item;
            }
        }))
        setEmployeeLeavesApprovals(employeeLeavesApprovals);
    }

    // function to changes the leaves status to false (means the leave status of the employee will be false now when come to this function)
    const rejectLeave = (rejectId, rejectName) => {
        employeeLeavesApprovals = (employeeLeavesApprovals.map(item => {
            if (item.employeName === rejectName) {
                return ({
                    leaveRecords: item.leaveRecords.map(record => {
                        if (record.leaveId === rejectId) return { type: record.type, reason: record.reason, leaveId: record.leaveId, fDate: record.fDate, approved: false }
                        else return record;
                    }), employeName: rejectName
                })
            } else {
                return item;
            }
        }))
        setEmployeeLeavesApprovals(employeeLeavesApprovals);
    }

    // Function for leave approved or rejected
    const leaveApprovedOrNot = (event) => {
        if (event.target.value === 'approved') {
            approveLeave(event.target.name, event.target.id);
        } else {
            rejectLeave(event.target.name, event.target.id);
        }
    }
    return (
        <Fragment>
            {/* Leave Approval Heading */}
            <Container className='mt-5'>
                <Row className='justify-content-center'>
                    <Col lg={10} xs={12} sm={12}>
                        <div
                            className=' text-center p-2'
                            style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}
                        >
                            <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'large' }}>
                                Leave Request
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>


            {/* Leave Approval Main Section */}
            {
                employeeLeavesApprovals !== undefined ?
                    <Container className='mt-3'>
                        <Row className='justify-content-center'>
                            <Col lg={10} xs={12} sm={12} style={{ paddingBottom: '70px' }}>
                                {
                                    shimmerShow === true ?
                                        <LeaveShimmer />
                                        :
                                        Object.keys(employeeLeavesApprovals).length > 0
                                            ?
                                            <>
                                                {
                                                    employeeLeavesApprovals.map((record, index) => (
                                                        <Accordion defaultActiveKey="0" key={index} className='mt-2'>
                                                            <Accordion.Item eventKey={index}>
                                                                <Accordion.Header>
                                                                    <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', textAlign: 'center' }}>
                                                                        {record.employeName}
                                                                    </span>
                                                                </Accordion.Header>

                                                                <Accordion.Body>
                                                                    <ListGroup variant='flush'>
                                                                        <ListGroupItem>
                                                                            <Table bordered responsive hover>
                                                                                <thead className='text-center'>
                                                                                    <tr>
                                                                                        <th>Date</th>
                                                                                        <th>Type</th>
                                                                                        <th>Reason</th>
                                                                                        <th>Approved</th>
                                                                                        <th>Rejected</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                {/* Iterating the leaves of employees */}
                                                                                <tbody>
                                                                                    {
                                                                                        record.leaveRecords.map((item, index) => (
                                                                                            <tr className='text-center' key={index}>
                                                                                                <td>{moment(item.fDate).format('DD-MM-YYYY')}</td>
                                                                                                <td>{item.type?.charAt(0).toUpperCase() + item.type?.slice(1)}</td>
                                                                                                <td>{item.reason?.charAt(0).toUpperCase() + item.reason.slice(1)}</td>
                                                                                                <td><input type="radio" name={item.leaveId} value='approved' style={{ cursor: "pointer" }} id={record.employeName} onChange={leaveApprovedOrNot} /></td>
                                                                                                <td><input type="radio" name={item.leaveId} value='rejected' style={{ cursor: "pointer" }} id={record.employeName} onChange={leaveApprovedOrNot} /></td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </Table>
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    ))
                                                }
                                                <Col lg={12} md={12} xs={12} className='mt-3 d-flex justify-content-end'>
                                                    <button className='btn btn-outline-primary' onClick={handleSubmit}>Submit</button>
                                                </Col>
                                            </>
                                            :
                                            <ListGroup variant='flush' style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                                <ListGroup.Item>
                                                    <span className='fw-semibold pointer' style={{ color: '#605C68', fontSize: 'larger' }}>
                                                        Record Does Not Exist.
                                                    </span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                }
                            </Col>
                        </Row>
                    </Container>
                    : <LeaveShimmer />
            }
            <ToastContainer />
        </Fragment >
    )
}

export default LeaveApproval;       