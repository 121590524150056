import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { DatatableWrapper, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable'
import { ShimmerTable } from 'react-shimmer-effects';
import HRMSApi from '../../api/HRMSApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../layout/Footer';

const Feedback = () => {
    const [body, setBody] = useState([]);
    const [rowData, setRowData] = useState({ category: '', subCategory: '', description: '' });
    const [isSpinner, SetIsSpinner] = useState(true);

    useEffect(() => {
        getFeedbackData();
    }, []);

    const getFeedbackData = async () => {
        const result = await HRMSApi.getFeedbackRecords();
        // console.log('##result', result)
        if (result.success) {
            if (result.records.feedbackRecords) {
                setBody(result.records.feedbackRecords);
            }
            SetIsSpinner(false)
        }
    }

    //table headers
    const header = [
        // { title: 'Date', prop: 'fDate', isFilterable: true },
        { title: 'Category', prop: 'category', isFilterable: true },
        { title: 'Sub Category', prop: 'subCategory', isFilterable: true },
        { title: 'Description', prop: 'description', isFilterable: true },
    ];

    //Randomize data of the table columns.Note that the fields are all using the `prop` field of the headers.
    const labels = { beforeSelect: " " }

    const handleChange = (event) => {
        setRowData({ ...rowData, [event.target.name]: event.target.value })
    }
    const handleSubmit = async () => {
        if (rowData.category && rowData.subCategory) {
            SetIsSpinner(true);
            const result = await HRMSApi.insertFeedbackRecord(rowData);
            if (result.success) {
                const successMessage = 'Record saved successfully.';
                const isSuccessMessage = result.message.toLowerCase().includes(successMessage.toLowerCase());
                isSuccessMessage ? toast.success(result.message, { position: toast.POSITION.TOP_RIGHT }) : toast.error('Insertion failed', { position: toast.POSITION.TOP_RIGHT });
                setRowData({ category: '', subCategory: '', description: '' });
                getFeedbackData();
            }
        } else {
            toast.error("Category & Sub Category fields are required.", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    return (
        <>
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10} sm={12} xs={12}>
                        <div className="p-2 text-center" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px" }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>Feedback</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {!isSpinner ?
                <>
                    <Container className='mt-3'>
                        <Row>
                            <Col></Col>
                            <Col lg={10} sm={12} xs={12}>
                                <Card className='h-100' style={{ height: "27rem", borderTop: "2px solid #4f6479", }}>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6} sm={12} xs={12}>
                                                <Form.Group className='mb-3'>
                                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                        Category
                                                    </Form.Label>
                                                    <Form.Select
                                                        required
                                                        name="category"
                                                        onChange={handleChange}
                                                        value={rowData.category || ''}
                                                    >
                                                        <option value="">-- Select Category --</option>
                                                        <option value="appreciation">Appreciation</option>
                                                        <option value="complaint">Complaint</option>
                                                        <option value="suggestion">Suggestion</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12} xs={12}>
                                                <Form.Group className='mb-3'>
                                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                        Sub Category
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        value={rowData.subCategory || ''}
                                                        type="text"
                                                        name="subCategory"
                                                        placeholder='Enter sub category here...'
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} sm={12} xs={12}>
                                                <Form.Group className='mb-3'>
                                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                        Description
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="description"
                                                        value={rowData.description || ''}
                                                        placeholder='Enter description here...'
                                                        onChange={handleChange}
                                                        rows={2}
                                                        maxLength={255}
                                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', resize: 'none' }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>

                                    <Card.Footer style={{ backgroundColor: "white" }} >
                                        <Row>
                                            <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                                <button className='btn btn-outline-primary float-end' onClick={handleSubmit}>
                                                    Save
                                                </button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>

                    <Container className='mt-3 mb-5' style={{ paddingBottom: "60px" }}>
                        <Row>
                            <Col></Col>
                            <Col lg={10} sm={12} xs={12}>
                                {body ?
                                    <DatatableWrapper
                                        body={body}
                                        headers={header}
                                        paginationOptionsProps={{ initialState: { rowsPerPage: 10, options: [10, 15, 20] } }}
                                    >
                                        <Row>
                                            <Col lg={12} sm={12} xs={12} className='table-min-div'>
                                                <Table striped className="data-table">
                                                    <TableHeader />
                                                    <TableBody />
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center justify-content-end ">
                                            <Col lg={12} xs={12} sm={12} className="d-flex align-items-center justify-content-end">
                                                <span className='me-2 mt-2'>
                                                    Show
                                                </span>
                                                <PaginationOptions labels={labels} />
                                                <div className='mt-2 mx-2'>
                                                    Records
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4 align-items-center justify-content-end ">
                                            <Col lg={12} xs={12} sm={12}>
                                                <div className='text-end mt-3'>
                                                    <Pagination />
                                                </div>
                                            </Col>
                                        </Row>
                                    </DatatableWrapper>
                                    : <ShimmerTable row={3} col={3} />
                                }
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </> :
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            }
            <ToastContainer />
            <Footer />
        </>

    )
}

export default Feedback
