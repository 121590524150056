/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";

const DescriptionModal = ({ show, onHide, timesheetData }) => {
    const [selectedDescription, setSelectedDescription] = useState()

    useEffect(() => {
        const data = timesheetData[show.index];
        if (data) {
            const dataForSelectedDate = data[show?.day];
            if (dataForSelectedDate) {
                setSelectedDescription(dataForSelectedDate)
            }
        }
    }, []);

    const handleChange = (event) => {
        setSelectedDescription({ ...selectedDescription, [event.target.name]: event.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (selectedDescription.description !== undefined && selectedDescription.description !== '') {
        if (selectedDescription.description !== undefined) {
            timesheetData[show.index][show?.day] = selectedDescription;
            onHide();
        }
    }

    return (
        <>
            <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered size='md'>
                <Modal.Header closeButton onClick={onHide} className=''>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Project Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form noValidate >
                                <Row>
                                    <Col lg={8} sm={12} xs={12}>
                                        <Form.Group className=''>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                Project Name
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                name="Project Name"
                                                value={show?.label}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} sm={12} xs={12}>
                                        <Form.Group className=''>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                Date
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                name="Project Name"
                                                value={show?.day}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12} sm={12} xs={12}>
                                        <Form.Group className='mt-3'>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">Description
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                placeholder="Enter Description..."
                                                value={selectedDescription?.description}
                                                onChange={handleChange}
                                                maxLength={255}
                                                rows={8}
                                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', resize: 'none' }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={onHide}>Close</button>
                    <button className='btn btn-outline-primary' onClick={handleSubmit}>Save</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DescriptionModal
