/**
 * @author: Abdul Pathan
 */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";

const LeaveStatusUpdate = (props) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleRevoked = () => {
        setIsButtonClicked(true);
        props.revoked(); // Call the revoked function
    };

    return (
        <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered size='md'>
            <Modal.Header closeButton onClick={props.onHide} disabled={isButtonClicked}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}<br />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12} sm={12} xs={12}>
                        <Form noValidate className="mb-0">
                            <h5 className="pb-3">Are you sure to revoke your leave?</h5>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <button className='btn btn-outline-primary' disabled={isButtonClicked} onClick={props.onHide}> No </button>
                    {props.table === "leaves" && (
                        <button className='btn btn-outline-primary mx-2' onClick={handleRevoked} disabled={isButtonClicked}> Yes </button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default LeaveStatusUpdate
