/**
 * @author: Abdul Pathan
 */
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import HRMSApi from '../../api/HRMSApi';
import Footer from '../layout/Footer';
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tax12BBFormJSON from './tax12BBForm.json';
const existingJSONData = JSON.parse(JSON.stringify(tax12BBFormJSON));

const Tax12BBForm = () => {
    const [selectedRegime, setSelectedRegime] = useState();
    const [isSpinner, setIsSpinner] = useState(false);
    const [oldRegimeShowHide, setOldRegimeShowHide] = useState(false);
    const [regimeRuleShowHide, setRegimeRuleShowHide] = useState(false);
    const [nameAndYear, setNameAndYear] = useState({ name: '', year: '' })
    const [tax12BBForm, setTax12BBForm] = useState(existingJSONData);
    const [currentMonth, setCurrentMonthe] = useState(new Date().getMonth() + 1);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [monthDateDisable, setMonthDateDisable] = useState(false);

    useEffect(() => {
        fetchTax12BBFromRecords();
    }, [])

    // fetch Tax 12BB Form Records
    const fetchTax12BBFromRecords = async () => {
        const nextYear = currentYear + 1;
        const yearRange = `${currentYear}-${nextYear}`;
        const token = localStorage.getItem("token");
        const decodedToken = jwt_decode(token);
        setNameAndYear({ name: decodedToken?.username, year: yearRange });

        const result = await HRMSApi.getTaxForm12BBRecords();
        //console.log('get Tax 12BB Form Records:', result)
        if (result.success) {
            setIsSpinner(true);
            if (result?.records.incrementDate) {
                const monthDate = new Date(result.records.incrementDate);
                const fetchMonthNumber = monthDate.getMonth() + 1;
                const currentDate = new Date(); // Get current date
                const nextOneDays = new Date(currentDate);
                const nextTwoDays = new Date(currentDate);
                const nextThreeDays = new Date(currentDate);
                //console.log('currentDate', currentDate.getDate())
                //console.log('nextOneDays.getDate()', nextOneDays.getDate())
                if (currentDate.getDate() === 1) {
                    nextOneDays.setDate(currentDate.getDate());
                    nextTwoDays.setDate(currentDate.getDate() + 1);
                    nextThreeDays.setDate(currentDate.getDate() + 2);
                } else if (currentDate.getDate() === 2) {
                    nextOneDays.setDate(currentDate.getDate() - 1);
                    nextTwoDays.setDate(currentDate.getDate());
                    nextThreeDays.setDate(currentDate.getDate() + 1);
                } else if (currentDate.getDate() === 3) {
                    nextOneDays.setDate(currentDate.getDate() - 2);
                    nextTwoDays.setDate(currentDate.getDate() - 1);
                    nextThreeDays.setDate(currentDate.getDate());
                }
                // Get the day numbers for each of the next three consecutive days
                const nextOneDayNumber = nextOneDays.getDate();
                const nextTwoDayNumber = nextTwoDays.getDate();
                const nextThreeDayNumber = nextThreeDays.getDate();
                //console.log('Day numbers for the next three days:', nextOneDayNumber, nextTwoDayNumber, nextThreeDayNumber);
                if (result.records.regime === null || result.records.regime === '') {
                    setMonthDateDisable(false);
                }
                else if ((currentMonth === fetchMonthNumber) || (currentMonth === (fetchMonthNumber + 1) && (nextOneDayNumber === 1 || nextTwoDayNumber === 2 || nextThreeDayNumber === 3))) {
                    setMonthDateDisable(false);
                } else {
                    setMonthDateDisable(true);
                }
            }

            if (result.records.x12BBFormDetails.length > 0) {
                const existingData = JSON.parse(JSON.stringify(tax12BBFormJSON));
                const newData = result.records;
                // Merge newData into existingData
                existingData.regime = newData.regime;
                existingData.financialYear = newData.financialYear;
                existingData.employeeName = newData.employeeName;
                existingData.contactId = newData.contactId;

                // Iterate through newData's x12BBFormDetails and update or add subcategory amounts in existingData
                newData.x12BBFormDetails.forEach(newDetail => {
                    const existingDetail = existingData.x12BBFormDetails.find(existingDetail => existingDetail.category === newDetail.category);
                    if (existingDetail) {
                        newDetail.subCategory.forEach(newSubDetail => {
                            const existingSubDetail = existingDetail.subCategory.find(existingSubDetail => existingSubDetail.name === newSubDetail.name);
                            if (existingSubDetail) {
                                existingSubDetail.amount = parseInt(newSubDetail.amount);
                            } else {
                                existingDetail.subCategory.push(newSubDetail);
                            }
                        });
                    } else {
                        existingData.x12BBFormDetails.push(newDetail);
                    }
                });
                setTax12BBForm(existingData);
                setSelectedRegime(result.records.regime);
            } else {
                setTax12BBForm(result.records);
                setSelectedRegime(result?.records.regime);
            }
        } else {//Tax 12BB Form is Not Available
            setIsSpinner(true);
        }
    }

    //handle radio button change
    const handleCheckboxClick = (event) => {
        setSelectedRegime(event.target.value);
        setRegimeRuleShowHide(true)
        if (event.target.value === 'Old Regime') {
            setOldRegimeShowHide(true);
            const existingData = JSON.parse(JSON.stringify(tax12BBFormJSON));
            setTax12BBForm(existingData);
        } else {
            setOldRegimeShowHide(false);
        }
    }

    // OLD REGIME || handle change
    const handleChange = (e, categoryIndex, subcategoryIndex) => {
        const { value } = e.target;
        tax12BBForm.x12BBFormDetails[categoryIndex].subCategory[subcategoryIndex].amount = value;
    }

    // validation || only number allow
    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46)) {
            e.preventDefault();
        }
    }

    // Radio || Submit 
    const handleSubmit = async () => {
        if (selectedRegime) {
            setIsSpinner(false);
            if (selectedRegime === 'New Regime') {
                const x12Form = {
                    financialYear: nameAndYear.year,
                    regime: selectedRegime
                }
                const result = await HRMSApi.createTax12BBFormRecords(x12Form);
                // console.log('New create Tax 12BB Form Records', result);
                if (result.success) {
                    const successMessage = 'Record saved successfully.';
                    const isSuccessMessage = result.record.toLowerCase().includes(successMessage.toLowerCase());
                    if (isSuccessMessage) {
                        toast.success(result.record, { position: toast.POSITION.TOP_RIGHT });
                        fetchTax12BBFromRecords();
                    }
                    else {
                        toast.error(result.record, { position: toast.POSITION.TOP_RIGHT });
                        setSelectedRegime();
                        setRegimeRuleShowHide(false);
                        setIsSpinner(true);
                    }
                }

            } else if (selectedRegime === 'Old Regime') {
                setOldRegimeShowHide(true);
                const updatedX12BBFormDetails = tax12BBForm.x12BBFormDetails.filter(category => {
                    category.subCategory = category.subCategory.filter(subItem => parseFloat(subItem.amount) > 0);
                    return category.subCategory.length > 0;
                });

                const x12Form = {
                    financialYear: nameAndYear.year,
                    regime: selectedRegime,
                    x12BBFormDetails: updatedX12BBFormDetails
                }

                const result = await HRMSApi.createTax12BBFormRecords(x12Form);
                // console.log('Old create Tax 12BB Form Records: ', result);
                if (result.success) {
                    const successMessage = 'Record saved successfully.';
                    const isSuccessMessage = result.record.toLowerCase().includes(successMessage.toLowerCase());
                    if (isSuccessMessage) {
                        toast.success(result.record, { position: toast.POSITION.TOP_RIGHT });
                        fetchTax12BBFromRecords();
                    } else {
                        toast.error(result.record, { position: toast.POSITION.TOP_RIGHT });
                        setIsSpinner(true);
                        setSelectedRegime();
                        setRegimeRuleShowHide(false);
                        setOldRegimeShowHide(false)
                    }
                }
            }
        } else {
            toast.error("Please select a Radio icon.", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    return (
        <>
            {/* HEADER */}
            <Container className='mt-5'>
                <Row className='text-center'>
                    <Col></Col>
                    <Col lg={10} xs={12} sm={12}>
                        <div className=' text-center p-2' style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }} >
                            <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'large' }}>
                                12BB Form
                            </span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {isSpinner ?
                <>
                    {/* NEW REGIME || OLD REGIME */}
                    <Container>
                        <Row>
                            <Col></Col>
                            <Col lg={10} sm={12} xs={12}>
                                <Card className='mt-2' style={{ border: "none", borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                                    <Card.Body>
                                        <Row >
                                            <Col lg={6} sm={12} xs={12} className='mb-2'>
                                                <span className='mx-2' style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }} >
                                                    Employee Name: {nameAndYear?.name}
                                                </span>
                                            </Col>
                                            <Col lg={6} sm={12} xs={12}>
                                                <span className='mx-2' style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }} >
                                                    Financial Year: {nameAndYear?.year}
                                                </span>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col lg={6} sm={6} xs={6}>
                                                <label style={{ fontSize: "14px" }}>
                                                    <input
                                                        className='form-check-input mx-2'
                                                        type="radio"
                                                        name="new_regime"
                                                        value="New Regime"
                                                        checked={tax12BBForm.regime === 'New Regime' ? true : selectedRegime === 'New Regime'}
                                                        onChange={handleCheckboxClick}
                                                        disabled={tax12BBForm.regime && (tax12BBForm.regime === 'New Regime' || tax12BBForm.regime === 'Old Regime')}
                                                    />New Regime
                                                </label>
                                            </Col>
                                            <Col lg={6} sm={6} xs={6}>
                                                <label style={{ fontSize: "14px" }}>
                                                    <input
                                                        className='form-check-input mx-2'
                                                        type="radio"
                                                        name="old_regime"
                                                        value="Old Regime"
                                                        checked={tax12BBForm.regime === 'Old Regime' ? true : selectedRegime === 'Old Regime'}
                                                        onChange={handleCheckboxClick}
                                                        disabled={tax12BBForm.regime && (tax12BBForm.regime === 'New Regime' || tax12BBForm.regime === 'Old Regime')}
                                                    />Old Regime
                                                </label>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer style={{ backgroundColor: "white" }} >
                                        <Row className='m-2'>
                                            <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                                <button className='btn btn-outline-primary float-end mx-2' onClick={handleSubmit} disabled={monthDateDisable}>
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>

                    {/* Some rules need to known */}
                    {(regimeRuleShowHide || tax12BBForm?.regime) &&
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col lg={10} sm={12} xs={12}>
                                    <Card className='mt-2' style={{ border: "none", borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                                        <Card.Body>
                                            <Row>
                                                <Col lg={6} sm={12} xs={12}>
                                                    <span className='mx-2' style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }} >
                                                        Some rules need to known
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Card.Body>

                                        <Card.Footer style={{ backgroundColor: "white" }} >
                                            <Row>
                                                <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                                    <ol>
                                                        <li>
                                                            Employee cannot modify the option chosen between New / Old tax Regime after selection.
                                                            However, an employee can change the option while filing their income tax return at their
                                                            personal end. The company is not involved at this stage and not responsible for any tax
                                                            payable / refund arising out of such change of option.
                                                        </li>
                                                        <li>
                                                            Income tax return shall be filed by the employee within the due date specified by the Govt.
                                                            (Generally its July 31 st of every year)
                                                        </li>
                                                        <li>
                                                            If the employee opts for the new method, then the employee need not submit tax
                                                            declaration / proofs.
                                                        </li>
                                                        <li>
                                                            If the employee opts for the old method, then the employee need to submit investment
                                                            proofs &amp; documents.
                                                        </li>
                                                        <li>
                                                            If Employee Opt New Regime, then he is not eligible for deductions / Exemptions like HRA
                                                            <ul>
                                                                <li>House Rental Allowance (HRA)</li>
                                                                <li>Interest on Housing loan ( self occupied property )</li>
                                                                <li>Deductions under Chapter VIA - Sec 80C, 80D, NPS(employee contr.) etc.</li>
                                                                <li>Standard Deduction.</li>
                                                                <li>Other Allowances.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    }

                    {/* Old Regime */}
                    {(oldRegimeShowHide || tax12BBForm.regime === 'Old Regime') && (
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col lg={10} sm={12} xs={12}>
                                    <Card className='mt-2' style={{ border: "none", borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                                        <Card.Body>
                                            {tax12BBForm?.x12BBFormDetails.map((category, index) => (
                                                <div key={index}>
                                                    <Row className='mb-4'>
                                                        <Col lg={12} sm={12} xs={12} className='mb-2'>
                                                            <span style={{ fontSize: "14px", fontWeight: "900", color: "rgb(121, 108, 154)" }}>
                                                                Section {category.category}
                                                            </span>
                                                        </Col>

                                                        <Col lg={12} sm={12} xs={12}>
                                                            <table className="table table-striped table-hover table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col" style={{ width: "83%" }}>Description</th>
                                                                        <th scope="col">Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {category.subCategory.map((subItem, subIndex) => (
                                                                        <tr key={subIndex}>
                                                                            <td>{subIndex + 1}</td>
                                                                            <td>{subItem.name}</td>
                                                                            <td>
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        disabled={subItem.name === "Contributions by an individual made under Employees' Provident Fund Scheme (EPF)" || monthDateDisable}
                                                                                        type="text"
                                                                                        name={subItem.name}
                                                                                        placeholder="Enter amount..."
                                                                                        defaultValue={subItem.amount || ''}
                                                                                        onChange={(e) => handleChange(e, index, subIndex)}
                                                                                        minlength="0"
                                                                                        maxlength="16"
                                                                                        step='0'
                                                                                        onKeyDown={handleKeyDown}
                                                                                    />
                                                                                </Form.Group>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    )}
                </>
                :
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            }

            <Container className='mt-5 mb-5'>
                <Row>
                    <Col lg={12} xs={12} sm={12}></Col>
                </Row>
            </Container>

            <ToastContainer />
            <Footer />
        </>
    )
}

export default Tax12BBForm