/**
 * @author: Abdul Pathan
 */
import React, { useState } from 'react'
import jwt_decode from "jwt-decode";
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import HRMSApi from '../../api/HRMSApi';

const UpdatePassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [user, setUser] = useState({ password: '', confirmpassword: '' });

    // console.log('update password location', location?.state)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // change input filed
    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user.password === user.confirmpassword && user.password !== '' && user.confirmpassword !== '') {
            setShow(false)
            let decodedToken = jwt_decode(location?.state);
            // console.log('decodedToken =>decodedToken', decodedToken)
            const obj = {
                authToken: location?.state,
                id: decodedToken.id,
                email: decodedToken.email,
                password: user.password,
                confirmpassword: user.confirmpassword
            }
            const result = await HRMSApi.updateUserPassword(obj);
            //console.log('result', result);
            if (result.success) {
                setIsLoading(true);
                setTimeout(() => {
                    navigate(`/login`)
                }, 5000);

            } else {
                //console.log('else....')
            }
        } else if (user.password === '' && user.confirmpassword !== '') {
            setShow(true);
            setErrorMessage('Password is required');
        } else if (user.password === '' && user.confirmpassword === '') {
            setShow(true);
            setErrorMessage('Please Enter required fields');
        } else if (user.password !== '' && user.confirmpassword === '') {
            setShow(true);
            setErrorMessage('Confirm Password is required');
        } else {
            setShow(true);
            setErrorMessage('Confirm Password doesn\'t match, Try again!');
        }
    }
    // console.log('user', user)
    return (
        <>
            {location?.state && <>
                {!isLoading ? (
                    <Container className='mt-5'>
                        <Row className=' '>
                            <Col lg={4} sm={12} xs={12}></Col>
                            <Col lg={4} sm={12} xs={12}>
                                <Card style={{ border: "0px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                    <Row className='pt-3'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <div className="text-center mb-3 mt-3">
                                                <img
                                                    src="indihrmslogo.jpg"
                                                    style={{ width: "180px" }}
                                                    alt=''
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12} sm={12} xs={12}>
                                            <div className='text-center pb-2'>
                                                <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large", textAlign: 'center' }}>Change Password</span>
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <Row className='mx-3 mb-3'>
                                                <Form onSubmit={handleSubmit}>
                                                    <Col lg={12} sm={12} xs={12} className='mb-3 text-center'>
                                                        <Alert variant="danger" show={show} className="error-alert">
                                                            {errorMessage}
                                                        </Alert>
                                                    </Col>

                                                    <Col lg={12} sm={12} xs={12} style={{ position: 'relative' }}>
                                                        <Form.Group className="mx-3" controlId="formBasicFirstName">
                                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName" >
                                                                New Password
                                                                <i class="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type={showPassword ? "text" : "password"}
                                                                name="password"
                                                                maxLength={10}
                                                                minLength={4}
                                                                placeholder="Enter your new password"
                                                                onChange={handleChange}
                                                                style={{ borderRadius: "20px" }}
                                                            />
                                                        </Form.Group>
                                                        <i className={`${showPassword === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                                            onClick={toggleShowPassword} title={`${showPassword === false ? "Show" : "Hide"}`}
                                                            style={{ right: '27px', bottom: '8px', position: 'absolute', zIndex: '999', cursor: "pointer" }}
                                                        ></i>
                                                    </Col>

                                                    <Col lg={12} sm={12} xs={12} className="mt-3" style={{ position: 'relative' }}>
                                                        <Form.Group className="mx-3" controlId="formBasicPhone">
                                                            <Form.Label className="form-view-label" htmlFor="formBasicPhone" >
                                                                Confirm Password
                                                                <i class="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                                            </Form.Label>

                                                            <Form.Control
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                name="confirmpassword"
                                                                disabled={user.password.length >= 4 ? false : true}
                                                                placeholder="Enter your confirm password"
                                                                onChange={handleChange}
                                                                maxLength={10}
                                                                style={{ borderRadius: "20px" }}
                                                                minLength={4}
                                                            />
                                                        </Form.Group>
                                                        <i className={`${showConfirmPassword === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                                            onClick={toggleShowConfirmPassword} title={`${showConfirmPassword === false ? "Show" : "Hide"}`}
                                                            style={{ right: '27px', bottom: '8px', position: 'absolute', zIndex: '999', cursor: "pointer" }}
                                                        ></i>
                                                    </Col>

                                                    <Col lg={12} sm={12} xs={12} className="mt-3">
                                                        <button className="btn mt-2" type="submit"
                                                            style={{
                                                                fontSize: '15px', fontWeight: "600", width: '100%',
                                                                borderRadius: '20px', backgroundColor: '#266D8D', color: 'white', padding: '8px'
                                                            }}
                                                        >Submit
                                                        </button>
                                                    </Col>
                                                </Form>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col lg={4} sm={12} xs={12}></Col>
                        </Row>
                    </Container>
                ) :
                    (
                        <div className="">
                            <div className="sk-cube-grid">
                                <div className="sk-cube sk-cube1"></div>
                                <div className="sk-cube sk-cube2"></div>
                                <div className="sk-cube sk-cube3"></div>
                                <div className="sk-cube sk-cube4"></div>
                                <div className="sk-cube sk-cube5"></div>
                                <div className="sk-cube sk-cube6"></div>
                                <div className="sk-cube sk-cube7"></div>
                                <div className="sk-cube sk-cube8"></div>
                                <div className="sk-cube sk-cube9"></div>
                            </div>
                        </div>
                    )
                }
            </>
            }
        </>
    )
}

export default UpdatePassword