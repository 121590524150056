import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Form as BootstrapForm, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import HRMSApi from "../api/HRMSApi";

const Login = () => {
  const navigate = useNavigate();
  // const [validated, setValidated] = useState(false);
  const [credentials, setCredentials] = useState({ email: "@ibirdsservices.com", password: "" });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState({});
  const [verifyOTP, setVerifyOTP] = useState("");
  const [recievedOTP, setRecievedOTP] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [otpexpired, setotpexpired] = useState(false);
  const [showEmail, setShowEmail] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(60);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); // Run this effect every 1000ms (1 second)
    return () => {
      clearInterval(interval);
    };
  }, [seconds]); // Re-run this effect whenever 'seconds' changes


  // input handle change value
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const modifiedEmail = value.replace(/\.com.*/, '.com');//// Remove any characters, words, and numbers after ".com"
      setCredentials(prevCredentials => ({ ...prevCredentials, [name]: modifiedEmail }));
    } else {
      setCredentials(prevCredentials => ({ ...prevCredentials, [name]: value }));
    }
  };

  // login 
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    //   setValidated(true);
    //   return;
    // }
    // setValidated(true);

    if (credentials.password === '' || credentials.email === '') {
      setShow(true);
      setErrorMessage('Required field missing.');
    } else {
      let verificationEmail = await authApi.credentialsVerification(credentials.email, credentials.password);
      // console.log('verificationEmail', verificationEmail.userName)
      if (verificationEmail.success) {
        setShow(false)
        let email = credentials?.email;
        let parts = email.split("@");
        let maskedEmail = "******@" + parts[1];
        setShowEmail(maskedEmail);
        const name = verificationEmail.userName
        setUserName(name);
        generateOTP(verificationEmail.userName); // OTP generate 
      } else {
        setShowEmail();
        setShow(true);
        setErrorMessage(verificationEmail.error);
      }
    }
  };

  // OTP generate 
  const generateOTP = async (name) => {
    setMinutes(1);
    setSeconds(30);
    setotpexpired(false);

    const obj = { email: credentials.email, name: name }
    const sendVerificationCode = await HRMSApi.sendEmailOTPVerification(obj);
    //console.log("sendVerificationCode", sendVerificationCode);
    if (sendVerificationCode.success === true) {
      setRecievedOTP(true);
    }
  };

  // change input filed OTP
  const handleChangeOTP = (e) => {
    const inputValue = e.target.value;
    setVerifyOTP(inputValue);
  };

  useEffect(() => { }, [otpexpired]);
  // OTP Verifiction
  const handleClickVerifyOTP = async () => {
    //console.log(seconds, minutes);
    if (verifyOTP == '' || verifyOTP == null) {
      setShow(true);
      setErrorMessage("Required field Missing");
      return;
    }

    if (seconds === 0 && minutes === 0) {
      //console.log("call exprie");
      setotpexpired(true);
      var obj = {
        email: credentials.email,
        currentotp: verifyOTP,
        otpexpired: true,
      };
      //console.log("obj ", obj);
      let verificationOtp = await HRMSApi.otpverification(obj);
      //console.log("verificationOtp", verificationOtp);
      //console.log(verificationOtp)
      if (verificationOtp.success === false) {
        setIsLoading(false);
        setShow(true);
        setErrorMessage(verificationOtp.message);
        return;
      }
    }
    //console.log("call verify", verifyOTP,otpexpired);


    if (verifyOTP && otpexpired === false) {
      let obj = {
        email: credentials.email,
        currentotp: verifyOTP,
        otpexpired: otpexpired,
      };
      //console.log("obj ", obj);
      let verificationOtp = await HRMSApi.otpverification(obj);
      //console.log("verificationOtp", verificationOtp);
      if (verificationOtp.success === true) {
        setIsLoading(true);
        verifyAuthentication();
      } else {
        setIsLoading(false);
        //console.log('Incorrect OTP entered');
        setShow(true);
        setErrorMessage("OTP is not correct.");
        return false;
      }
    }
    else {
      setShow(true);
      setErrorMessage("Required field missing.");
    }
  };

  //Authentication
  const verifyAuthentication = async () => {
    if (credentials.email && credentials.password) {

      const result = await authApi.login(credentials.email, credentials.password);
      // console.log('result', result)

      if (result.success) {
        const responce = await HRMSApi.getContactRecordByEmail();

        if (responce.success) {
          localStorage.setItem("token", result.authToken);

          setTimeout(() => {
            navigate(`/`)
          }, 500);
        } else {
          setRecievedOTP()
          setIsLoading(false)
          setShow(true);
          setErrorMessage(responce.message);
        }
      }
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {!isLoading ? (
        <div
          style={{
            height: "100vh",
            overflow: "auto",
            backgroundImage: "url('background-image.png')",
            backgroundSize: "cover",
            backgroundPosition: "75% 0%",
            width: "100%",
          }}
        >
          <Container className="mt-5 pt-5">
            <Row
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
              }}
            >

              <Col lg={8} sm={12} xs={12} className="p-3">
                <img alt="" src="indihrms.png" style={{ width: "100%", borderRadius: "5px" }} />
              </Col>

              <Col lg={4} sm={12} xs={12} className="p-3">
                <Row className="mx-3" >
                  <Col lg={12} sm={12} xs={12}>
                    <div className="mt-2 text-center mb-3">
                      <img
                        alt=""
                        src="indihrmslogo.jpg"
                        style={{ width: "200px" }}
                        className="mb-2"
                      />
                    </div>
                  </Col>

                  {!recievedOTP ?
                    (
                      <>
                        <Form onSubmit={handleSubmit} >
                          <Col lg={12} sm={12} xs={12}>
                            <Alert variant="danger" show={show} className="error-alert">
                              {errorMessage}
                            </Alert>
                          </Col>

                          <Col lg={12} sm={12} xs={12} className="mt-3">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                onChange={handleChange}
                                placeholder="Enter your official Email"
                                value={credentials.email}
                                style={{ borderRadius: "20px" }}
                              />
                            </Form.Group>
                          </Col>

                          {/* <Col lg={12} sm={12} xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                onChange={handleChange}
                                placeholder="Enter your Password"
                                value={credentials.password}
                                style={{ borderRadius: "20px" }}
                              />
                            </Form.Group>
                          </Col> */}

                          <Col lg={12} sm={12} xs={12}>
                            <BootstrapForm.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <BootstrapForm.Label>Password</BootstrapForm.Label>

                              <div className="password-input-group">
                                <InputGroup>
                                  <BootstrapForm.Control
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    onChange={handleChange}
                                    placeholder="Enter your Password"
                                    value={credentials.password}
                                    style={{ borderRadius: "20px", position: 'relative' }}
                                  />
                                  <i className={`${showPassword === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                    onClick={toggleShowPassword} title={`${showPassword === false ? "Show" : "Hide"}`}
                                    style={{ borderRadius: "20px", position: 'absolute', right: '13px', top: '9px', zIndex: '999', cursor: "pointer" }}
                                  ></i>
                                  {/* <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    onClick={toggleShowPassword}
                                    className="password-icon"
                                    style={{ borderRadius: "20px", position: 'absolute', right: '13px', top: '9px', zIndex: '999' }}
                                  /> */}
                                </InputGroup>
                              </div>
                            </BootstrapForm.Group>
                          </Col>

                          <Col className="mt-4" lg={12} sm={12} xs={12}>
                            <button className="btn" type="submit"
                              style={{ fontSize: '15px', fontWeight: "600", width: '100%', borderRadius: '20px', backgroundColor: '#266D8D', color: 'white' }}
                            >Login</button>
                          </Col>

                          <Col className="mt-4 text-center" lg={12} sm={12} xs={12}>
                            <a href="/forgetpassword" style={{ fontSize: '11px' }} >Forgot Password?</a>
                          </Col>

                        </Form>
                      </>
                    ) :
                    (
                      <>
                        <Row className="text-center">
                          <Col lg={12} sm={12} xs={12}>
                            <span style={{ color: "grey", fontSize: "medium" }}>
                              Enter your verification code
                            </span>
                          </Col>

                          <Col className="mt-3" lg={12} sm={12} xs={12}>
                            <span style={{ fontSize: "13px" }}>
                              Please enter one-time password to verify your account. A code has been sent to {showEmail}
                            </span>
                          </Col>
                          {/* <Col lg={12} sm={12} xs={12} className='mt-3'>
                            <span style={{ color: "#605C68", fontSize: "small" }}>
                              <i>A code has been sent to *******@ibirdsservices.com</i>
                            </span>
                          </Col> */}
                        </Row>

                        <Row className="mx-3 mt-1" >
                          <>

                            <Col lg={12} sm={12} xs={12} className="text-center">
                              <Alert variant="danger" show={show} className="error-alert text-center">
                                {errorMessage}
                              </Alert>
                            </Col>

                            <Col lg={12} sm={12} xs={12}>
                              <Form.Group className="my-3">
                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="otp"
                                  onChange={handleChangeOTP}
                                  style={{ borderRadius: "20px" }}
                                  placeholder="Enter OTP"
                                />
                              </Form.Group>
                            </Col>

                            {seconds > 0 || minutes > 0 ? (
                              <>
                                <Col className="text-center" lg={12} sm={12} xs={12}>
                                  Time Remaining:
                                  <span className="mx-2" style={{ fontWeight: 600 }}>
                                    {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                  </span>

                                  <br></br>
                                  {/* <button
                                    className="btn btn-outline-light"
                                    onClick={() => generateOTP(userName)}
                                    disabled={seconds > 0 || minutes > 0}
                                    style={{ color: seconds > 0 || minutes > 0 ? "blue" : "blue", borderRadius: "5px" }}
                                  >
                                    Resend OTP
                                  </button> */}
                                </Col>

                                {/* <Col className="text-center" lg={12} sm={12} xs={12}>
                                  <button
                                    className="btn btn-link"
                                    onClick={() => generateOTP(userName)}
                                    disabled={seconds > 0 || minutes > 0}
                                    style={{ color: seconds > 0 || minutes > 0 ? "blue" : "blue", borderRadius: "5px" }}
                                  >
                                    Resend OTP
                                  </button>
                                </Col> */}

                                <Col
                                  className="mt-2 mb-3"
                                  lg={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => handleClickVerifyOTP()}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      width: "100%",
                                      borderRadius: "20px",
                                      backgroundColor: "#266D8D",
                                      color: "white",
                                    }}
                                  >
                                    Verify
                                  </button>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col
                                  className="mt-2 mb-3"
                                  lg={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => handleClickVerifyOTP()}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      width: "100%",
                                      borderRadius: "20px",
                                      backgroundColor: "#266D8D",
                                      color: "white",
                                    }}
                                  >
                                    Verify
                                  </button>
                                </Col>
                                <Col
                                  className="text-center mb-3"
                                  lg={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <i>Didn't receive code?</i>
                                  {/* </Col>

                                <Col className="mt-3 text-center" lg={12} sm={12} xs={12}> */}
                                  <button
                                    className="btn p-0 mx-1"
                                    onClick={() => generateOTP(userName)}
                                    disabled={seconds > 0 || minutes > 0}
                                    style={{ color: seconds > 0 || minutes > 0 ? "blue" : "blue", borderRadius: "5px" }}
                                  >
                                    Resend OTP
                                  </button>
                                </Col>
                              </>
                            )}

                            {/* <Col className="mt-2 text-center" lg={12} sm={12} xs={12}>
                              <button
                                className="btn btn-outline-light"
                                onClick={() => generateOTP(userName)}
                                disabled={seconds > 0 || minutes > 0}
                                style={{ color: seconds > 0 || minutes > 0 ? "blue" : "blue", borderRadius: "5px" }}
                              >
                                Resend OTP
                              </button>
                            </Col> */}
                          </>
                        </Row>
                      </>
                    )
                  }
                </Row>

                <Row className="text-center mt-2 mx-3">
                  <Col lg={12} sm={12} xs={12} >
                    <Link to="https://www.facebook.com/ibirdsservices" target="_blank">
                      <span className="fa-stack mx-2">
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#3b5998", fontSize: "2.8rem" }} ></i>
                        <i className="fa-brands fa-facebook-f fa-stack-1x mt-2 mt-2" style={{ color: "#fff", fontSize: "1.5rem" }}></i>
                      </span>
                    </Link>
                    <Link to="https://www.ibirdsservices.com/" target="_blank">
                      <span className="fa-stack mx-2">
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "tomato", fontSize: "2.8rem" }} ></i>
                        <i className="fa-brands fa-google fa-stack-1x mt-2" style={{ color: "#fff", fontSize: "1.5rem" }}></i>
                      </span>
                    </Link>
                    <Link to="https://twitter.com/ibirdsservices" target="_blank">
                      <span className="fa-stack mx-2">
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#0d95e8", fontSize: "2.8rem" }} ></i>
                        <i className="fa-brands fa-twitter fa-stack-1x mt-2" style={{ color: "#fff", fontSize: "1.5rem" }}></i>
                      </span>
                    </Link>

                    <Link to="https://www.instagram.com/ibirds_software" target="_blank">
                      <span className="fa-stack mx-2">
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#E4405F", fontSize: "2.8rem" }} ></i>
                        <i className="fa-brands fa-instagram fa-stack-1x mt-2" style={{ color: "#fff", fontSize: "1.5rem" }}></i>
                      </span>
                    </Link>

                    <Link to="https://www.linkedin.com/company/ibirds-services/mycompany" target="_blank">
                      <span className="fa-stack mx-2">
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#0A66C2", fontSize: "2.8rem" }} ></i>
                        <i className="fa-brands fa-linkedin fa-stack-1x mt-2" style={{ color: "#fff", fontSize: "1.5rem" }}></i>
                      </span>
                    </Link>

                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>

          <div class="copyright-container">
            <div class="vert-align mt-2">
              <p class="headline" style={{ fontSize: '11px', }}>Powered by
                <span className="pl-3">
                  <b style={{ fontSize: '13px', }}> iBirds Services</b>
                </span>
              </p>
            </div>
          </div>

        </div >
      ) :
        (
          <div className="">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Login;