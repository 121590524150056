import React from 'react'
import { Col, Row } from "react-bootstrap";
const Footer = () => {
    return (
        <footer className="p-3 text-white" style={{ background: "rgb(70 86 102)", position: "fixed", zIndex: '999' }}>
            <Row className="g-0">
                <Col lg={10} className=" text-center">
                    ©Copyright<a className="text-white" href="https://ibirdsservices.com">
                        <b className='mx-2'>iBirds Software Services Pvt. Ltd.</b> All Rights Reserved
                    </a>
                </Col>
            </Row>
        </footer>
    )
}

export default Footer
