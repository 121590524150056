import React, { useEffect, useState } from "react";
import { Badge, Button, Image, Modal, NavDropdown } from "react-bootstrap";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
// import { MenuItem } from "react-bootstrap-typeahead";
// import HRMSApi from "../../api/HRMSApi";
import * as constants from "../../constants/CONSTANT";


const Header = ({ socket, notifications }) => {
  // const location = useLocation()
  // const [refreshNotification, setRefreshNotification] = useState(new Date());
  // const [showBellAlert, setShowBellAlert] = useState(false);
  // const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  // const [messages, setMessages] = useState(0);
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState({});
  // const [showNotification, setShowNotification] = useState();

  // useEffect(() => {
  //   HRMSApi.getContactRecordByEmail().then((result) => {
  //     if (result.success) {
  //       console.log('header', result)
  //     }
  //   })
  // }, [])

  const [accountName, setAccountName] = useState();
  const token = localStorage.getItem("token");
  let decodedToken = jwt_decode(token);

  useEffect(() => {
    setAccountName(decodedToken.contactDetails.Name)
    if (!localStorage.getItem("token")) navigate("/login");
    // try {
    //   setUserInfo(jwt_decode(localStorage.getItem('token')));
    // } catch (error) {
    //   console.log(error)
    // }
    // socket?.on("greetings", (data) => {
    //   messageList();
    // })
  }, []);

  // const messageList = () => {
  //   async function init() {
  //     let tasks = await HRMSApi.fetchUnreadMessages();
  //     if (tasks && tasks?.length > 0) {
  //       setUnreadMsgCount(tasks.length);
  //       setMessages(tasks)
  //       setShowBellAlert(true);
  //     } else {
  //       setMessages([])
  //       setUnreadMsgCount(0);
  //     }
  //   }
  //   init();
  // };

  const logout = () => {
    //authApi.logout();
    localStorage.clear();
    navigate("/login");
  };

  const toggleSidebar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  };

  // const closeNotification = () => {
  //   setShowNotification(false);
  // };

  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="" style={{ marginBottom: isMobile ? "1rem" : "0" }}>
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}

        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              <img
                src="header_user_logo.jpg"
                alt=""
                height={40}
              ></img>
            </Nav.Link> */}
            <Nav.Link href="#" className="d-flex p-0" style={{ alignItems: "center" }}>
              <span className="mx-2" style={{ fontSize: ".9rem" }}>
                {accountName === constants?.Company_Name ? 'Company' : 'College'}
              </span>
              <Badge style={{ fontSize: ".9rem" }} bg="primary mx-2">
                {accountName}
              </Badge>
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto d-flex align-items-center ">

            <div className="icon-wrapper">
              <i className="fa-solid fa-bell cic mx-3" title="Notification" style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
            </div>
            {/* {showNotification &&
              <Notification notifications={messages} closeNotification={closeNotification} refreshNotification={refreshNotification} />} */}
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (<></>
            )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
};

export default Header;