import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
// import jwt_decode from "jwt-decode";
import Main from "./components/layout/Main";
import { PaySlips, PaySlipsView } from "./components/pay_slips";
import TimeSheet from "./components/time_sheet/TimeSheet";
import 'react-calendar/dist/Calendar.css'
import ChangePassword from "./components/ChangePassword";
import Leave from "./components/leaves/Leave";
import { ForgotPassword, SendOTP, UpdatePassword } from "./components/forgot_password";
import HandBook from "./components/handbook/HandBook";
import { TaxDeductedSource } from "./components/tax_deducted_source";
import Form16 from "./components/tax_form_16/Form16";
import Tax12BBForm from "./components/tax_12BB_form/Tax12BBForm";
import Feedback from "./components/feedback/Feedback";
import LeaveApproval from "./components/leaveapproval";
import MedicalCard from "./components/medicalcard";

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  // const [permissions, setPermissions] = useState();
  const [connectedSocket, setConnectedSocket] = useState();

  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);

  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>

          {/* updated user password  || START || 12 January */}

          <Route path="/forgetpassword" element={<ForgotPassword />} />
          <Route path="/send_otp" element={<SendOTP />} />
          <Route path="/update_password" element={<UpdatePassword />} />

          {/* updated user password  || END*/}

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Main socket={connectedSocket} />}>
            <Route path="/" index element={<Home />} />

            <Route
              path="/payslips"
              element={<PaySlips />}
            />
            <Route
              path="/payslips/view"
              element={<PaySlipsView />}
            />

            <Route
              path="/timesheet"
              element={<TimeSheet />}
            />

            <Route
              path="/changepassword"
              element={<ChangePassword />}
            />
            <Route
              path="/leaves"
              element={<Leave />}
            />

            <Route
              path="/taxdeductedsource"
              element={<TaxDeductedSource />}
            />

            <Route
              path="/handbook"
              element={<HandBook />}
            />

            <Route
              path="/taxformsixteen"
              element={<Form16 />}
            />

            <Route  
              path="/medicalcard"
              element={<MedicalCard />}
            />

            <Route
              path="/tax12BBForm"
              element={<Tax12BBForm />}
            />

            <Route
              path="/feedback"
              element={<Feedback />}
            />


            <Route
              path="/leaveapproval" 
              element={<LeaveApproval />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;