/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from 'react';
import { ListGroup, Accordion, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from '../layout/Footer';
import HRMSApi from '../../api/HRMSApi';
// import moment from 'moment';
import { ShimmerTable } from 'react-shimmer-effects';
// import payslip from './payslip.json';

const PaySlips = () => {
  const navigate = useNavigate();
  const [payrollRecords, setPayrollRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [attachment, setAttachment] = useState();

  useEffect(() => {
    fetchPayrollRecords();
  }, []);

  const fetchPayrollRecords = async () => {
    const result = await HRMSApi.getPaySlipRecords();//from salesforce Get Payroll Records Based on Contact Id
    // console.log('get PayRoll Record########: ', result);
    if (result.success) {
      setPayrollRecords(result.records);
    } else {
      setPayrollRecords();
    }
  };

  const handleClick = (record) => {
    if (record) {
      navigate(`/payslips/view`, { state: record });
    }
  };

  // Function to group pay slips by year
  const groupPaySlipsByYear = () => {
    let groupedPaySlips = {};
    if (payrollRecords) {
      payrollRecords?.forEach((record) => {
        record?.payrolls.forEach((pay) => {
          const year = record.financialYear
          if (!groupedPaySlips[year]) {
            groupedPaySlips[year] = [];
          }
          groupedPaySlips[year].push(pay);
        });
      });
    }
    return groupedPaySlips;
  };

  const groupedPaySlips = groupPaySlipsByYear();

  // payslip download
  const handleClickPaySlipDownload = async (record) => {
    try {
      if (record?.payrollId) {
        setIsLoading(true);
        const serverDownloadUrl = await HRMSApi.attchementurl(record?.payrollId);
        // console.log('serverDownloadUrl', serverDownloadUrl);
        setIsLoading(false);

        const newWindow = window.open(serverDownloadUrl, '_blank');
        if (newWindow) {
          newWindow.addEventListener('load', () => {
            const downloadLink = newWindow.document.createElement('a');
            downloadLink.href = serverDownloadUrl;
            // downloadLink.href = `${serverDownloadUrl}${password ? `?password=${encodeURIComponent(password)}` : ''}`;
            // downloadLink.download = 'PaySlip_Download';
            // newWindow.document.body.appendChild(downloadLink);
            // downloadLink.click();
            let attachementname = record.attachmentName
            downloadLink.download = attachementname;
            downloadLink.click();
          });
        }
        // else {
        //   console.error('Failed to open a new window to download the file.');
        // }
      }
    } catch (error) {
      console.error('Error during download:', error);
      setIsLoading(false);
    }
  }



  return (
    <>
      <Container className='mt-5'>
        <Row className='text-center'>
          <Col></Col>
          <Col lg={10} xs={12} sm={12}>
            <div
              className=' text-center p-2'
              style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}
            >
              <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'large' }}>
                View PaySlips
              </span>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>

      {!isLoading ? (
        <>
          {payrollRecords ? (
            <>
              <Container className='mt-3'>
                <Row>
                  <Col></Col>
                  <Col lg={10} xs={12} sm={12} style={{ paddingBottom: '70px' }}>

                    {Object.keys(groupedPaySlips).length > 0 ? (
                      <>
                        <Accordion>
                          {Object.entries(groupedPaySlips).map(([year, paySlips], index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                              <div>
                                <Accordion.Header>
                                  <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', textAlign: 'center' }}>
                                    {year}
                                  </span>
                                </Accordion.Header>
                              </div>

                              <Accordion.Body>

                                <ListGroup variant='flush'>
                                  {paySlips.map((record, index) => (
                                    <ListGroup.Item key={index} >
                                      <span className='mx-3' style={{ display: 'inline-block' }}>
                                        <i className='fa-regular fa-file-lines' style={{ color: '#03a9f4', fontSize: 'large', cursor: "pointer" }}></i>
                                      </span>
                                      <span onClick={() => handleClick(record)} key={index} className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', display: 'inline-block', cursor: "pointer" }}>
                                        {record.payrollName}
                                      </span>

                                      <span className="text-end">
                                        <i className="fa-solid fa-download"
                                          onClick={() => handleClickPaySlipDownload(record)}
                                          title='Download'
                                          style={{ color: "rgb(3, 169, 244)", fontSize: '14px', marginInline: '15px', float: 'right', cursor: "pointer" }}
                                        ></i>
                                      </span>
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>

                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </>
                    ) : (
                      <ListGroup variant='flush' style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <ListGroup.Item>
                          <span className='fw-semibold pointer' style={{ color: '#605C68', fontSize: 'larger' }}>
                            Record Does Not Exist.
                          </span>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            </>
          ) : (
            <Container className='mt-1'>
              <Row>
                <Col></Col>
                <Col lg={10} xs={12} sm={12} style={{ paddingBottom: '70px' }}>
                  <ShimmerTable row={3} col={2} />
                </Col>
                <Col></Col>
              </Row>
            </Container>
          )}
        </>
      ) : (
        <div className="">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default PaySlips;