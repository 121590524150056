/**
 * @author: Abdul Pathan
 */
import React, { useState } from 'react';
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import authApi from '../../api/authApi';
// import jwt_decode from "jwt-decode";

const ForgotPassword = () => {
    const navigate = useNavigate();
    // const [validated, setValidated] = useState(false);
    const [emailAddress, setEmailAddress] = useState({ email: "@ibirdsservices.com" });
    const [show, setShow] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState();

    // input handle change value
    const handleChange = (e) => {
        setEmailAddress({ ...emailAddress, [e.target.name]: e.target.value });
    };

    // handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        let verificationEmail = await authApi.updatePasswordEmailVerify(emailAddress.email);
        //console.log('verificationEmail', verificationEmail)

        if (verificationEmail.success) {
            if (verificationEmail.authToken) {
                navigate('/send_otp', { state: verificationEmail.authToken });
            }
        } else {
            setShow(true);
            setErrorMessage(verificationEmail.error);
        }
    }

    return (
        <>
            <Container className='mt-5'>
                <Row className=' '>
                    <Col lg={4} sm={12} xs={12}></Col>
                    <Col lg={4} sm={12} xs={12}>
                        <Card style={{ border: "0px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                            <Row className='pt-3'>
                                <Col lg={12} sm={12} xs={12}>
                                    <div className="text-center mt-3">
                                        <img
                                            src="indihrmslogo.jpg"
                                            style={{ width: "180px" }}
                                            className="mb-2"
                                            alt=''
                                        />
                                    </div>
                                </Col>

                                <Col lg={12} sm={12} xs={12}>
                                    <div className='text-center pb-2'>
                                        <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large", textAlign: 'center' }}>Change Password</span>
                                    </div>
                                </Col>

                                <Col lg={12} sm={12} xs={12}>
                                    <Row className='mx-3 mb-3'>
                                        <Form onSubmit={handleSubmit}>
                                            <Col lg={12} sm={12} xs={12} className='mb-3'>
                                                <Alert variant="danger" show={show} className="error-alert">
                                                    {errorMessage}
                                                </Alert>
                                            </Col>

                                            <Col lg={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className='pb-2'><b>Enter your Email</b></Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        value={emailAddress.email}
                                                        onChange={handleChange}
                                                        placeholder="Enter your official Email"
                                                        style={{ borderRadius: "20px" }}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12} sm={12} xs={12}>
                                                <button className="btn mt-2" type="submit"
                                                    style={{ fontSize: '15px', fontWeight: "600", width: '100%', borderRadius: '20px', backgroundColor: '#266D8D', color: 'white', padding: '8px' }}
                                                >Submit</button>
                                            </Col>
                                        </Form>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={4} sm={12} xs={12}></Col>
                </Row>
            </Container>

        </>
    )
}

export default ForgotPassword
