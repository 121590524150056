import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import PubSub from 'pubsub-js';
import HRMSApi from "../api/HRMSApi";
import Footer from "./layout/Footer";


const ChangePassword = ({ userId }) => {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({ password: '', confirmpassword: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        if (user.password === user.confirmpassword && user.password !== '' && user.confirmpassword !== '') {
            //console.log('user', user);
            const result = await HRMSApi.updateUser(user);
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Password updated successfully' });
            //console.log(result);
        } else if (user.password === '' && user.confirmpassword !== '') {
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error', message: 'Password is required' });
        } else if (user.password === '' && user.confirmpassword === '') {
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error', message: 'Please Enter required fields' });
        } else if (user.password !== '' && user.confirmpassword === '') {
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error', message: 'Confirm Password is required' });
        } else {
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error', message: 'Confirm Password doesn\'t match, Try again!' });
            //console.log('');
        }

    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10}>
                        <div className="text-center p-2" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px", }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>Change Password</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Container >
                <Row className="mt-3">
                    <Col></Col>
                    <Col lg={10} sm={12} xs={12}>
                        <Form className="mt-3" onSubmit={handleSubmit}>

                            <Row className="pb-3">
                                <Col lg={12} sm={12} xs={12} style={{ position: 'relative' }}>
                                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFirstName"
                                        >
                                            New Password
                                            <i class="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                        </Form.Label>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            maxLength= {10}
                                            placeholder="Enter your password"
                                            onChange={handleChange}
                                            required
                                            minLength={4}
                                        />
                                    </Form.Group>
                                    <i className={`${showPassword === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                        onClick={toggleShowPassword} title={`${showPassword === false ? "Show" : "Hide"}`}
                                        style={{ right: '30px', bottom: '8px', position: 'absolute', zIndex: '999', cursor: "pointer" }}
                                    ></i>
                                </Col>
                                <Col lg={12} sm={12} xs={12} className="mt-3" style={{ position: 'relative' }}>
                                    <Form.Group className="mx-3" controlId="formBasicPhone">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPhone"
                                        >
                                            Confirm Password
                                            <i class="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                        </Form.Label>
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmpassword"
                                            disabled={user.password.length >= 4  ? false : true}
                                            placeholder="Enter confirm password"
                                            maxLength={10}
                                            onChange={handleChange}
                                            required
                                            minLength= {4}
                                        />
                                    </Form.Group>
                                    <i className={`${showConfirmPassword === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                        onClick={toggleShowConfirmPassword} title={`${showPassword === false ? "Show" : "Hide"}`}
                                        style={{ right: '30px', bottom: '8px', position: 'absolute', zIndex: '999', cursor: "pointer" }}
                                    ></i>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Container>
                <Row >
                    <Col></Col>
                    <Col lg={10} sm={12} xs={12}>
                        <button className='btn btn-outline-primary float-end' onClick={handleSubmit}
                        > Update
                        </button>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
};

export default ChangePassword;