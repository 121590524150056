import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
const ConfirmRemoveRow = (props) => {

    return (
        <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered size='md'>
            <Modal.Header closeButton onClick={props.onHide} className=''>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}<br />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12} sm={12} xs={12}>
                        <Form noValidate className="mb-0">
                            <h5 className="pb-3">Are you sure to delete row?</h5>
                            <i>{props.message}</i>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <button className='btn btn-outline-primary' onClick={props.onHide}> No </button>
                    {props.table === "timesheet" && (
                        <button className='btn btn-outline-primary mx-2' onClick={props.removeSelectedRow}> Yes </button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmRemoveRow
