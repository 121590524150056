/**
 * @author: Abdul Pathan
 */
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import HRMSApi from '../../api/HRMSApi';
import Footer from '../layout/Footer';

const Form16 = () => {
    const [body, setBody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchForm16Data();
    }, []);

    const fetchForm16Data = async () => {
        const result = await HRMSApi.getTaxForm16Records();
        // console.log('result: ', result);
        setIsLoading(false);
        if (result.success) {
            const sortedRecords = result?.records.slice().sort((a, b) => {
                return a.financialyear.localeCompare(b.financialyear);// Compare the financialyear properties of two items for sorting
            });
            setBody(sortedRecords);
        }
    }

    const handleClick = async (item) => {
        setIsLoading(true);
        try {
            if (item?.form16Id) {
                const formId = item?.form16Id;
                const serverDownloadUrl = await HRMSApi.getTaxForm16PDF(formId);
                // console.log('serverDownloadUrl', serverDownloadUrl)
                setIsLoading(false);
                const newWindow = window.open(serverDownloadUrl, '_blank');
                if (newWindow) {
                    newWindow.addEventListener('load', () => {
                        const downloadLink = newWindow.document.createElement('a');
                        downloadLink.href = serverDownloadUrl;
                        downloadLink.download = `Form-16-download-${item?.financialyear}.pdf`; // Specify custom filename here
                        downloadLink.style.display = 'none';
                        newWindow.document.body.appendChild(downloadLink);
                        downloadLink.click();
                        // newWindow.close(); // Close the window after download is initiated
                    });
                }
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    }

    return (
        <>
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10} sm={12} xs={12}>
                        <div className="p-2 text-center" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px" }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>View Form-16</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {!isLoading ? (<>
                <Container className='mt-2 mb-2'>
                    <Row>
                        <Col></Col>
                        <Col lg={10} xs={12} sm={12}>
                            <Row>
                                <Col lg={12} xs={12} sm={12}>
                                    {body?.length > 0 ? (
                                        <ListGroup variant='flush' style={{ borderRadius: "5px", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)" }}>
                                            {body?.map((item, index) => (
                                                <ListGroup.Item key={index}>
                                                    <span className='fw-semibold' style={{ color: '#605C68', fontSize: 'larger', textAlign: 'center' }}>
                                                        {item?.financialyear}
                                                    </span>
                                                    <span className="text-end">
                                                        <i
                                                            className="fa-solid fa-download pt-1"
                                                            title='Download'
                                                            onClick={() => handleClick(item)}
                                                            style={{ color: "rgb(3, 169, 244)", fontSize: '15px', marginInline: '15px', float: 'right', cursor: "pointer" }}
                                                        ></i>
                                                    </span>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    ) :
                                        <ListGroup variant='flush'>
                                            <ListGroup.Item>
                                                <span className='fw-semibold' style={{ color: '#605C68' }}>
                                                    Form-16 is Not Available
                                                </span>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </>) :
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            }

            <Footer />
        </>
    )
}

export default Form16
