/**
 * @author: Abdul Pathan
 */
import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, startOfMonth, endOfMonth, isSameMonth, isBefore } from 'date-fns';
import "bootstrap/dist/css/bootstrap.min.css";
import Select from 'react-select';
import { Col, Container, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import HRMSApi from '../../api/HRMSApi';
import Footer from '../layout/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";
import DescriptionModal from './DescriptionModal';
import ConfirmRemoveRow from './ConfirmRemoveRow';
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../../constants/CONSTANT";
import TimeSheetPdf from './TimeSheetPdf';
import { useNavigate } from 'react-router-dom';

const TimeSheet = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(selectedDate, { weekStartsOn: 0 }));
    const [monthWeekDate, setMonthWeekDate] = useState([]);
    const [mainData, setMainData] = useState([])
    const [optionProject, setOptionProject] = useState([]);
    const token = localStorage.getItem("token");
    let decodedToken = jwt_decode(token);
    //cosole.log('decodedToken',decodedToken.contactDetails.Id)
    // Assuming "contactDetails" is a property in the decoded token
    const contactDetailsString = decodedToken.contactDetails;
    //cosole.log('contactDetailsString',contactDetailsString)
    const [modalShow, setModalShow] = useState(false);
    // const [weekData, setWeekData] = useState({});
    const [isSpinner, setIsSpinner] = useState(true);
    const [holidayDates, setHolidayDates] = useState([]);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [monthyear, setmonthyear] = useState({});


    const navigate = useNavigate();
    useEffect(() => {
        if (decodedToken.contactDetails.Name === constants.College_Name) navigate("/");
        fetchRecords();
    }, []);

    const daysInWeek = eachDayOfInterval({
        start: currentWeekStart,
        end: endOfWeek(currentWeekStart, { weekStartsOn: 0 }),
    });

    const fetchRecords = async () => {
        const holidaysResponse = await HRMSApi.getHolidays();//holiday calenders data
        if (holidaysResponse.success) {
            // const holidayDatesArray = holidaysResponse.records.map((item) => item.ActivityDate);
            const holidayDatesArray = holidaysResponse.records.filter(item => !item.Name.includes("Optional")).map(item => item.ActivityDate);
            setHolidayDates(holidayDatesArray);
        }

        const projectResult = await HRMSApi.getProjectRecords();//all projects
        if (projectResult.success) {
            let arr = projectResult.records.map(item => ({
                value: item.Id,
                label: item.Name
            }));
            setOptionProject(arr);
        }

        const currentDay = new Date()
        const currentMonthStart = startOfMonth(currentDay);
        const currentMonthEnd = endOfMonth(currentDay);

        const formattedStart = format(currentMonthStart, 'yyyy-MM-dd');
        const formattedEnd = format(currentMonthEnd, 'yyyy-MM-dd');
        // console.log('Formatted Start Date:', formattedStart);
        // console.log('Formatted End Date:', formattedEnd);

        const currentWeekStart = startOfWeek(currentDay, { weekStartsOn: 0 });
        const currentWeekEnd = endOfWeek(currentDay, { weekStartsOn: 0 });

        const adjustedWeekStart = currentWeekStart < currentMonthStart ? currentMonthStart : currentWeekStart;
        const adjustedWeekEnd = currentWeekEnd > currentMonthEnd ? currentMonthEnd : currentWeekEnd;

        const startDate = format(adjustedWeekStart, 'yyyy-MM-dd');
        const endDate = format(adjustedWeekEnd, 'yyyy-MM-dd');

        fetchTimesheetMonthlyPDFData(formattedStart, formattedEnd);
        addDefaultRow(startDate, endDate);// Add default row
        timesheetData(startDate, endDate)
    }

    // handle date change
    const handleDateChange = async (newDate) => {
        // fetchTimesheetMonthlyData()
        setIsSpinner(true)
        setSelectedDate(newDate);
        const newWeekStart = startOfWeek(newDate, { weekStartsOn: 0 });
        setCurrentWeekStart(newWeekStart);

        const currentMonthStart = startOfMonth(newDate);
        const currentMonthEnd = endOfMonth(newDate);

        const formattedStart = format(currentMonthStart, 'yyyy-MM-dd');
        const formattedEnd = format(currentMonthEnd, 'yyyy-MM-dd');
        // console.log('Formatted Start Date333:', formattedStart);
        // console.log('Formatted End Date333:', formattedEnd);

        const currentWeekStart = startOfWeek(newDate, { weekStartsOn: 0 });
        const currentWeekEnd = endOfWeek(newDate, { weekStartsOn: 0 });

        const adjustedWeekStart = currentWeekStart < currentMonthStart ? currentMonthStart : currentWeekStart;
        const adjustedWeekEnd = currentWeekEnd > currentMonthEnd ? currentMonthEnd : currentWeekEnd;

        const startDate = format(adjustedWeekStart, 'yyyy-MM-dd');
        const endDate = format(adjustedWeekEnd, 'yyyy-MM-dd');

        // Add default row
        if (isSameMonth(newDate, new Date())) {
            addDefaultRow(startDate, endDate);
        } else {
            setMainData([])
        }
        fetchTimesheetMonthlyPDFData(formattedStart, formattedEnd);
        timesheetData(startDate, endDate);// Update timesheet data
    };


    // Function to add a default row
    const addDefaultRow = (startDate, endDate) => {
        const currentWeekStart = new Date(startDate);
        const end = new Date(endDate);
        const weeks = [];

        while (currentWeekStart <= end) {
            const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 0 });
            const daysInWeek = eachDayOfInterval({
                start: currentWeekStart,
                end: weekEnd,
            });

            const formattedWeek = daysInWeek.map(day => format(day, 'yyyy-MM-dd'));
            weeks.push({
                weekStart: formattedWeek[0],
                weekEnd: formattedWeek[formattedWeek.length - 1],
                days: formattedWeek,
            });
            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
        }

        const days = weeks.flatMap(week => week.days);
        // return weeks;
        const tableColumns = {};
        const weekDays = days.map(dt => format(new Date(dt), 'yyyy-MM-dd'));
        weekDays.forEach(day => {
            tableColumns[day] = { value: 0, description: '', leave: null };
        });
        // Create a copy of mainData and add the new row
        const temp = [{ projectId: '', ...tableColumns }];
        setMainData(temp);
    };

    // fetch PDF Monthly Data
    const fetchTimesheetMonthlyPDFData = async (startDate, endDate) => {
        const startDateObject = new Date(startDate);
        const startMonthName = startDateObject.toLocaleString('default', { month: 'long' });
        const currentYear = startDateObject.getFullYear(); // Get current year

        if (startDate && endDate) {
            let obj = {
                startDate: startDate,
                endDate: endDate,
            }

            let result = await HRMSApi.getTimesheetRecordsMonth(obj);
            if (result.success) {
                setmonthyear({ ...monthyear, monthname: startMonthName, year: currentYear, data: result.records });
            }
        }
    }

    // common method
    const timesheetData = async (startDate, endDate) => {
        const weekDays = daysInWeek.map(dt => format(dt, 'yyyy-MM-dd').toString());
        const contactRecs = contactDetailsString;
        //cosole.log('contactRecs',contactRecs)
        if (contactRecs) {
            const obj = {
                startDate: startDate,
                endDate: endDate
            }
            //cosole.log('###obj', obj)
            const result = await HRMSApi.getWorkingHours(obj);
            // console.log('###result salesforce data', result);
            if (result.success) {
                let mainDataMap = {};
                //cosole.log('###result.success', result);

                result?.records?.projectDetails.forEach(item => {
                    if (item.workDate >= startDate && item.workDate <= endDate) {
                        if (mainDataMap.hasOwnProperty(item.projectId)) {
                            mainDataMap[item.projectId][item.workDate] = { value: (item.workingHours ? item.workingHours : 0), description: item.description, leave: item.leave };
                        } else {
                            let obj = {};
                            obj[item.workDate] = { value: (item.workingHours ? item.workingHours : 0), description: item.description, leave: item.leave };
                            mainDataMap[item.projectId] = obj;
                        }
                    }
                });

                let weeklyTimeSheetRecords = [];
                Object.entries(mainDataMap).forEach(([key, value]) => {
                    let clone = Object.assign({}, value);
                    weekDays.forEach(day => {
                        if (!clone.hasOwnProperty(day)) {
                            clone[day] = { value: 0, description: null, leave: null, isPreviousMonth: true };
                        }
                    });
                    weeklyTimeSheetRecords.push({ projectId: key, ...clone });
                });

                if (weeklyTimeSheetRecords.length > 0) {
                    setMainData(weeklyTimeSheetRecords);
                }
                setIsSpinner(false)
            } else {
                // console.log('###Record not exist')
            }
        }
    }

    // Add Row
    const handleAddRow = () => {
        if (!isSameMonth(selectedDate, new Date())) {
            toast.error("Adding rows is only allowed for the current month.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        if (mainData && mainData[mainData?.length - 1]?.projectId === "") {
            toast.error("Don't add multiple rows with empty project.", { position: toast.POSITION.TOP_RIGHT });
            return false;
        }

        let tableColumns = {}
        const weekDays = daysInWeek.map(dt => format(dt, 'yyyy-MM-dd').toString());
        weekDays.forEach(day => {
            tableColumns[day] = { value: 0, description: '', leave: null };
        });

        let temp = JSON.parse(JSON.stringify(mainData))
        temp.push({ projectId: '', ...tableColumns });
        setMainData(temp);
    };

    // Delete Row
    const handleDeleteRow = (index) => {
        if (!isSameMonth(selectedDate, new Date())) {
            toast.error("Delete feature is available only for current month.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        setDeleteModalShow(true);
        setRowData(index);
    };

    const removeSelectedRow = () => {
        if (rowData || rowData === 0) {
            let tempMainData = JSON.parse(JSON.stringify(mainData));
            if (tempMainData.length > 1) {// Check if there is more than one row
                tempMainData.splice(rowData, 1);
                setMainData(tempMainData);
                setDeleteModalShow(false);
            } else {
                setDeleteModalShow(false);
                toast.warning("Cannot delete the last row.", { position: toast.POSITION.TOP_RIGHT, });// Display a message if there is only one row
            }
        }
    }

    const handleHoursChange = (rowIndex, projectId, value, selectedDate) => {
        const isBackspace = value === '' || value === null;  // Check if the pressed key is backspace

        if (!isBackspace) {
            const newValue = parseFloat(value);

            if (isNaN(newValue)) {
                toast.warning("Please enter a valid number.", { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            const totalHours = calculateTotalHours(selectedDate);
            const decimal = totalHours - mainData[rowIndex][selectedDate].value + newValue;

            if (decimal <= 24) {
                mainData[rowIndex][selectedDate].value = value;
                setMonthWeekDate([...monthWeekDate, mainData[rowIndex][selectedDate].value = (value ? parseFloat(value) : 0)]);
            } else {
                mainData[rowIndex][selectedDate].value = 0;
                toast.warning("In a day, working hours can't exceed 24 hours.", { position: toast.POSITION.TOP_RIGHT });
            }
        } else {
            mainData[rowIndex][selectedDate].value = 0;
            setMonthWeekDate([...monthWeekDate, mainData[rowIndex][selectedDate].value = 0]);
        }
        // mainData[rowIndex][selectedDate].value = value;
        // setMonthWeekDate([...monthWeekDate, mainData[rowIndex][selectedDate].value = (value ? parseFloat(value) : 0)]);
    };

    // handle project change
    const handleProjectChange = (rowIndex, e, oldProjectId) => {
        e.index = rowIndex
        const selectedNewProjectId = e.value;

        // const index = mainData.findIndex(x => x.projectId === selectedNewProjectId);
        const isProjectAlreadySelected = mainData.some((row, index) => index !== rowIndex && row.projectId === selectedNewProjectId);
        if (isProjectAlreadySelected) {
            alert('Please choose another project, as project already selected.');
            return;
        }

        // if (index !== -1) {
        //     alert('Please choose another project, as project already selected.');
        //     return;
        // }

        const optionProjectIndex = optionProject.findIndex(option => option.value === selectedNewProjectId);
        if (optionProjectIndex === -1) {
            e.value = null;
        }

        let tempMainData = JSON.parse(JSON.stringify(mainData));
        tempMainData[rowIndex].projectId = selectedNewProjectId;
        setMainData(tempMainData);
    };

    // Total hours calculate
    const calculateTotalHours = (dt) => {
        return mainData ? Object.values(mainData).reduce((total, entry) => total + (entry.hasOwnProperty(dt) && entry[dt].value ? parseFloat(entry[dt].value) : 0), 0) : 0;
    };

    // Define the isWeekend function
    const isWeekend = (date) => {
        const day = new Date(date).getDay();
        return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
    }

    // handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        //cosole.log('## Main Data', mainData);
        //cosole.log('handleSubmit localStorage', decodedToken?.contactDetails?.Id);
        //cosole.log('selected Date', selectedDate)

        const currentMonthStart = startOfMonth(selectedDate);
        const currentMonthEnd = endOfMonth(selectedDate);
        const formattedStart = format(currentMonthStart, 'yyyy-MM-dd');
        const formattedEnd = format(currentMonthEnd, 'yyyy-MM-dd');

        const currentWeekStart = startOfWeek(selectedDate, { weekStartsOn: 0 });
        const currentWeekEnd = endOfWeek(selectedDate, { weekStartsOn: 0 });

        const adjustedWeekStart = currentWeekStart < currentMonthStart ? currentMonthStart : currentWeekStart;
        const adjustedWeekEnd = currentWeekEnd > currentMonthEnd ? currentMonthEnd : currentWeekEnd;

        const startDate = format(adjustedWeekStart, 'yyyy-MM-dd');
        const endDate = format(adjustedWeekEnd, 'yyyy-MM-dd');

        //cosole.log('startDate startDate', startDate);
        //cosole.log('endDate endDate', endDate);


        if (!isSameMonth(selectedDate, new Date())) {
            toast.error("Saving is only allowed for the current month.", { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        let timesheetRecords = [];
        let isBlankRow = false;

        mainData.forEach(item => {
            let { projectId } = item;
            if (projectId && projectId.trim().length > 0) {
                Object.entries(item).forEach(([date, hours]) => {
                    if (date !== 'projectId' && date !== 'description' && !hours.hasOwnProperty('isPreviousMonth')) {
                        if (date >= startDate && date <= endDate) {
                            timesheetRecords.push({
                                workingHours: hours.value,
                                description: hours.description,
                                workDate: date,
                                projectId
                            });
                        }
                    }
                });
            }
            else {
                isBlankRow = true;
            }
        });


        if (!isBlankRow) {
            if (decodedToken?.contactDetails?.Id) {
                const pdRecords = {
                    contactId: decodedToken?.contactDetails?.Id,
                    projectDetails: timesheetRecords
                }

                //cosole.log('transformedData', pdRecords);

                if (pdRecords.projectDetails.length > 0) {
                    setIsSpinner(true);
                    const result = await HRMSApi.createProjectRecords(pdRecords);
                    //cosole.log('###result', result)
                    if (result.success) {
                        fetchTimesheetMonthlyPDFData(formattedStart, formattedEnd);
                        setIsSpinner(false);
                        toast.success("Timesheet saved successfully.", { position: toast.POSITION.TOP_RIGHT });
                    }
                }
                else {
                    setIsSpinner(false);
                    toast.error("Please select at least one project.", { position: toast.POSITION.TOP_RIGHT });
                }
            }
        }
        else {
            setIsSpinner(false);
            toast.error("Blank rows are not allowed. Please select a project for each row.", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    // click edit icon and add description
    const handleChangeDescription = (rowIndex, day, e) => {
        // console.log('handleChangeDescription row', rowIndex, day, e?.value);

        if (e?.value) {
            e.index = rowIndex;
            e.day = day;
            setModalShow(e);
        } else {
            toast.error("Please select project.", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    // // show data pdf formate
    const handlePdfTimesheet = () => {
        setIsSpinner(true);
        const pdfTable = document.getElementById("divToPrint");
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        const documentDefinition = {
            info: { title: 'Employee Monthly Timesheet' },
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }



        setTimeout(() => {
            setIsSpinner(false);
            pdfMake.createPdf(documentDefinition).open()
        }, 500);
        handlePdfTimesheetDownload()
    }


    const handlePdfTimesheetDownload = () => {
        setIsSpinner(true);
        const pdfTable = document.getElementById("divToPrint");
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        const documentDefinition = {
            info: { title: 'Employee Monthly Timesheet' },
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        pdfMake.createPdf(documentDefinition).download(`Employee Monthly Timesheet-${monthyear.monthname} ${monthyear.year}.pdf`);

    }

    return (
        <>
            <Container className='mt-5'>
                <Row>
                    <Col></Col>
                    <Col lg={10}>
                        <div className=" text-center p-2" style={{ height: "40px", backgroundColor: "#ffffff", borderRadius: "5px", }}>
                            <span className='fw-semibold' style={{ color: "#605C68", fontSize: "large" }}>Timesheet</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            <Container className='mt-3'>
                <Row>
                    <Col ></Col>
                    <Col lg={6} sm={12} xs={12}></Col>

                    <Col lg={2} sm={12} xs={12}>
                        <div className='float-end mb-3' >
                            {!isSpinner ? (
                                <button className='btn btn-outline-primary' onClick={handlePdfTimesheet} disabled={!monthyear?.monthname}>
                                    <i className="fa fa-file-pdf-o" style={{ color: "#03a9f4", marginRight: "3px" }}></i>
                                    Monthly PDF
                                </button>
                            ) :
                                <button className='btn btn-outline-primary' disabled>
                                    <i className="fa fa-file-pdf-o" style={{ color: "#03a9f4", marginRight: "3px" }}></i>
                                    Monthly PDF
                                </button>
                            }
                        </div>
                    </Col>

                    <Col lg={2} sm={12} xs={12}>
                        <input
                            className='form-control'
                            type="date"
                            value={format(selectedDate, 'yyyy-MM-dd')}
                            onChange={(e) => handleDateChange(new Date(e.target.value))}
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

            {!isSpinner ? (
                <>
                    <Container className='mt-5 ' style={{ marginBottom: '30vh' }}>
                        <Row>
                            <Col></Col>
                            <Col lg={10} sm={12} xs={12} className='table-min-div'>
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: "12px", }} className='fw-bold' >Project</th>
                                            {daysInWeek.map((day, index) => (
                                                <th className='fw-bold' style={{ fontSize: "12px" }} key={index}>{format(day, 'EEE MM/dd')}</th>
                                            ))}
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {mainData && mainData.map((row, rowIndex) => (
                                            <tr key={row.projectId}>
                                                <td style={{ width: '15%' }}>
                                                    {optionProject && (
                                                        <Form.Group style={{ width: "150px" }}>
                                                            <Select
                                                                className="username"
                                                                placeholder="--Select--"
                                                                name={row.projectName}
                                                                onChange={(e) => handleProjectChange(rowIndex, e, row.projectId)}
                                                                value={optionProject.find((option) => option.value === row?.projectId) ? optionProject.find((option) => option.value === row?.projectId) : ''}
                                                                options={optionProject}
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        minHeight: 'auto',
                                                                        height: 'auto',
                                                                    }),
                                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                                    menuList: (provided) => ({
                                                                        ...provided,
                                                                        maxHeight: '100px',  // Adjust the max height as needed
                                                                        overflowY: 'auto',
                                                                    }),
                                                                }}
                                                                menuPortalTarget={document.body}
                                                            ></Select>
                                                        </Form.Group>
                                                    )}
                                                </td>

                                                {daysInWeek.map((day, colIndex) => (
                                                    <td key={`${row.projectId}-${colIndex}`} data-id={`${row.projectId}-${colIndex}`}>

                                                        <InputGroup className='mt-1 input-group-hours-class'>
                                                            <FormControl
                                                                type="number"
                                                                min='0'
                                                                max='24'
                                                                disabled={!isSameMonth(new Date(day), new Date())}
                                                                value={`${row.hasOwnProperty(format(day, 'yyyy-MM-dd')) ? row[format(day, 'yyyy-MM-dd')].value : 0}`}
                                                                onChange={(e) => handleHoursChange(rowIndex, row.projectId, e.target.value, format(new Date(day), 'yyyy-MM-dd'))}
                                                            />
                                                            {!isSameMonth(new Date(day), new Date()) ? (
                                                                <InputGroup.Text>
                                                                    <i className="fa-solid fa-pencil" style={{ color: "rgb(155 171 208)", fontSize: "15px", cursor: 'not-allowed' }}></i>
                                                                </InputGroup.Text>
                                                            ) : (
                                                                <InputGroup.Text>
                                                                    <span
                                                                        className='pointer'
                                                                        title="Provide your project description"
                                                                        data-index={rowIndex} data-project-id={row?.projectId}
                                                                        onClick={(e) => handleChangeDescription(rowIndex, format(day, 'yyyy-MM-dd'), optionProject.find((option) => option.value === row?.projectId))}
                                                                    >
                                                                        <i className="fa-solid fa-pencil" style={{ color: "rgb(155 171 208)", fontSize: "15px", cursor: "pointer" }}></i>
                                                                    </span>
                                                                </InputGroup.Text>
                                                            )}
                                                        </InputGroup>
                                                    </td>
                                                ))}

                                                <td className='pt-4 mx-3'>
                                                    <span className='pointer' onClick={() => handleDeleteRow(rowIndex)} title="Remove row">
                                                        <i className="fa-regular fa-trash-can" style={{ color: "red", fontSize: "15px", cursor: "pointer" }}></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td className='fw-bold'>TOTAL HOURS</td>
                                            {daysInWeek.map((day, dayIndex) => (
                                                <td>
                                                    <span
                                                        key={dayIndex}
                                                        className={`fw-bold text-center ${isWeekend(day) || holidayDates?.includes(format(day, 'yyyy-MM-dd')) || ((mainData[0]?.[format(day, 'yyyy-MM-dd')]?.leave !== null) && (mainData[0]?.[format(day, 'yyyy-MM-dd')]?.leave !== 'Half day'))
                                                            ? calculateTotalHours(format(day, 'yyyy-MM-dd')) > 0 ? 'weekend-yellow-highlight' : ''
                                                            : ''} `}
                                                    >
                                                        {calculateTotalHours(format(day, 'yyyy-MM-dd'))}
                                                    </span>
                                                </td>
                                            ))}
                                            <td></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col></Col>
                            <Col lg={10} sm={12} xs={12}>
                                <Row>
                                    <Col lg={6} sm={12} xs={12}>
                                        <i>***Please save your information before switching to other week.</i>
                                        <p><i>***Yellow Highlights: worked hours on Holiday/Weekends/Leave day.</i></p>
                                    </Col>
                                    <Col lg={6} sm={12} xs={12}>
                                        <div className='float-end'>
                                            <button className='btn btn-outline-primary mx-2' onClick={handleAddRow}>
                                                <i className="fa-solid fa-circle-plus" style={{ color: "#03a9f4", marginRight: "3px" }}></i>
                                                Add Row
                                            </button>
                                            <button className='btn btn-outline-primary' onClick={handleSubmit} >
                                                <i className="fa fa-save" style={{ color: "#03a9f4", marginRight: "3px" }}></i>
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <div>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            )}

            {modalShow && (
                <DescriptionModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    timesheetData={mainData}
                />
            )}

            {deleteModalShow && (
                <ConfirmRemoveRow
                    show={deleteModalShow}
                    onHide={() => setDeleteModalShow(false)}
                    removeSelectedRow={removeSelectedRow}
                    title="Confirm Delete"
                    message={`***It will remove row from screen, click on "Save" on main screen to permanent delete.`}
                    table="timesheet"
                />
            )}

            <ToastContainer />
            <Footer />

            <div className="App container mt-5" style={{ display: 'none' }}>
                <div id="divToPrint">
                    <TimeSheetPdf monthyear={monthyear} />
                </div>
            </div>
        </>
    );

}
export default TimeSheet